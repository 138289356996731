import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { textPopUpActions } from '../../redux/slice/textPopUp'
const ContactCard = ({ image, contactTitle, contactText, link }) => {
    const dispatch = useDispatch()
    return (
        <div className="col-md-3 col-sm-6 " data-aos="fade-down">
            <div className="contact-box">
                <div className="contact-icon">
                    <img src={image} alt="phone" />
                </div>
                <div className="contact-title">
                    <h4>{contactTitle}</h4>
                </div>
                <div className="contact-text">
                    {contactTitle == 'Text' ? <a className='cursor-pointer-scroll-up' onClick={() => dispatch(textPopUpActions.handleShow())}>{contactText}</a> : <Link to={link}>{contactText}</Link>}

                </div>
            </div>
        </div>
    )
}

export default ContactCard