import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AOS from 'aos';

import DiscountPage from './LandingPageComponents/DiscountPage';
import LandingPageForm from './LandingPageComponents/LandingPageForm';
import LandingPageHeader from './LandingPageComponents/LandingPageHeader';
import Campaign from '../Home/HomeComponents/Campaign';
import Testimonials from '../Testimonials/Testimonials';
import Footer from '../Footer/Footer';
import TextPopup from '../TextPopup/TextPopup';
import { LandingPageContactUsComponents } from './LandingPageComponents/LandingPageContactUsComponent';

export const LandingPage = () => {
  const { pathname } = useLocation();
  const [visible, setVisible] = useState(false);
  const show = useSelector(state => state.text.show);

  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, [pathname]);

  const toggleVisible = useCallback(() => {
    setVisible(document.documentElement.scrollTop > 100);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, [toggleVisible]);

  const scrollToTop = useCallback(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 500);
  }, []);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "USA Mail Direct",
            "alternateName": "USA Mail Direct",
            "url": "https://usamail.direct/",
            "logo": ""
          }
        `}
        </script>
        <title>The Best Direct Mail Service Company - USA Mail Direct</title>
        <meta name="description" content="Looking for the best direct mail service company? Look no further than USA Mail Direct. We provide top-notch direct mail services tailored to meet your needs. Contact us today!" />
      </Helmet>
      <main>
        <span onClick={scrollToTop} className={`scrollToTop cursor-pointer-scroll-up ${visible ? 'd-flex' : 'd-none'}`}>
          <i className="fas fa fa-arrow-up"></i>
        </span>
        {/* <TextPopup /> */}
        {show && <div className='overlay-visible'></div>}
        <LandingPageHeader />
        <LandingPageForm />
        <DiscountPage />
        <Campaign />
        <LandingPageContactUsComponents />
        <Testimonials />
        {/* <Footer /> */}
      </main>
    </>
  );
};
