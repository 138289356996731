
import React, { useEffect, useRef, useState } from 'react'
// import banner from '../../../assets/images/banner-img.jpg'
import banner from '../../../assets/images/banner-img.jpg'
import landingLogo from '../../../assets/images/logo.png'
import landingPageLogo from '../../../assets/images/landingPageLogo.png'
import emailjs from 'emailjs-com';
function LandingPageForm() {
    const [err, setError] = useState(null)
    const [response, setResponse] = useState(null)
    const formRef = useRef()
    const resetForm = () => {
        formRef.current.reset();
        setTimeout(() => {
            setResponse(null)
        }, 1500)
    }
    const sendEmail=(e)=>{
        e.preventDefault();
        let errors = {};
        const fieldNames = {
            name: ' Name',
            businessName: 'Business Name',
            emailAddress: 'Email Address',
            contact: 'Phone Number',
            message: 'Message'
        };
        const requiredFields = ['name', 'businessName', 'emailAddress', 'contact', 'message'];
        for (const field of requiredFields) {
            const value = e.target[field].value.trim();
            if (!value) {
                // Use the field as a key in the errors object
                errors[field] = `Please fill in the ${fieldNames[field]}  field.`;
            }
        }
        setError(errors);
        if (Object.keys(errors).length === 0) {
            const serviceID = 'service_nu2tww4';
            const templateID = 'template_tcd37u8';
            const publicKey = 'IqMFFU3Z2aSszwIQ-'

            const formData = {
                name: e.target['name'].value,
                businessName: e.target['businessName'].value,
                emailAddress: e.target['emailAddress'].value,
                contact: e.target['contact'].value,
                message: e.target['message'].value

            };


          console.log('form data',formData)

            // Send email using EmailJS
            emailjs.send(serviceID, templateID, formData, publicKey,)
                .then((response) => {
                    setResponse('Email sent successfully')
                    resetForm()
                })
                .catch((error) => {
                    setResponse('Failed to send email')
                    resetForm()
                });
        }
    }
    return (
        <section className="home-banner">
            <div className="banner-img"><img src={banner} alt="" /></div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="banner-logo" data-aos="zoom-in"><a href="#"><img src={landingPageLogo} alt /></a></div>
                    </div>
                    <div className="col-md-6">
                        <div className="banner-form" data-aos="zoom-out">
                            <h3>Let’s get your political message out now. </h3>
                            {response ? <div className='text-center h3 text-white'>{response}email send succesfully</div> : <>
                            <form ref={formRef} onSubmit={sendEmail}>
                            <div className="form-col">
                                <i className="fa-solid fa-user" />
                                <input type="text" className="form-feild" placeholder="Full Name" name='name' />
                                <label className='text-danger py-1'>{err?.name && err.name}</label>
                            </div>
                            <div className="form-col">
                                <i className="fa-solid fa-briefcase" />
                                <input type="text" className="form-feild" placeholder="Business"  name='businessName'/>
                                <label className='text-danger py-1'>{err?.businessName && err.businessName}</label>
                            </div>
                            <div className="form-col">
                                <i className="fa-solid fa-envelope" />
                                <input type="text" className="form-feild" placeholder="Email"  name='emailAddress' />
                                <label className='text-danger py-1'>{err?.emailAddress && err.emailAddress}</label>
                            </div>
                            <div className="form-col">
                                <i className="fa-solid fa-phone" />
                                <input type="text" className="form-feild" placeholder="Phone Number" name='contact' />
                                <label className='text-danger py-1'>{err?.contact && err.contact}</label>
                            </div>
                            <div className="form-col">
                                <i className="fa-solid fa-message" />
                                <textarea className="form-feild" placeholder="Write your message here..." defaultValue={""} name='message' />
                                <label className='text-danger py-1'>{err?.message && err.message}</label>
                            </div>
                            <div className="form-col">
                                <button type='submit' className="cus-btn">Submit</button>
                            </div>
                            </form>
                            </>}
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default LandingPageForm