import React from 'react'
import Campaign from './HomeComponents/Campaign'
import RecentArticles from '../Articles/ArticleComponents/RecentArticles'
import TopBar from './HomeComponents/HomeBanner'
import Testimonials from '../Testimonials/Testimonials'
import LetterBox from './HomeComponents/LetterBox/LetterBox'
import { Helmet } from 'react-helmet'

export const Home = () => {
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "USA Mail Direct",
        "alternateName": "USA Mail Direct",
        "url": "https://usamail.direct/",
        "logo": ""
      }
    `}
        </script>
        <title>The Best Direct Mail Service Company - USA Mail Direct</title>
        <meta name="description" content="Looking for the best direct mail service company? Look no further than USA Mail Direct. We provide top-notch direct mail services tailored to meet your needs. Contact us today!" />
      </Helmet>
      <main>
        <TopBar />
        <Campaign />
        <LetterBox />
        <Testimonials />
        <RecentArticles />

      </main>
    </>
  )
}
