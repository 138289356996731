import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import TitleBannerCard from '../commonCards/TitleBannerCard'
import Articles from './ArticleComponents/Articles'

export const ArticlesPage = () => {
  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    })
  }
  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <>
      <Helmet>
        <title>Useful Articles - Direct Mail Services - USA Mail Direct</title>
        <meta name="description" content="Need help with your direct mail marketing? Look no further! USA Mail Direct provides professional and cost-effective direct mail services. Contact us today to get started." />
      </Helmet>
      <TitleBannerCard title='ARTICLES' className='article-bg' />
      <Articles />
    </>
  )
}
