import React from 'react'
import { campaignData } from '../../../constants/constants'
import CampaignCard from '../../commonCards/CampaignCard'
import { useLocation } from 'react-router-dom'
const Campaign = () => {
  const location = useLocation()
  const pathname = location?.pathname
  return (
    <section className="mail-campaign sec_pd">
      <div className="container">
        {pathname === '/political-mail-marketing' ? null : <h2 className="main_title2 text-center" data-aos="fade-down">I’m an experienced mailer. I’m ready to get to work.
        </h2>}

        <div className="row mc_row" data-aos="fade-down" data-aos-delay="200" data-aos-duration="1400">
          {campaignData.map(campaign => <CampaignCard key={campaign.heading} {...campaign} />)}
        </div>
      </div>
    </section>
  )
}

export default Campaign