import { createSlice } from "@reduxjs/toolkit";



const initialState = {
  show: false,
  isSuccess: false
};

const textPopUpSlice = createSlice({
  name: "textPopUp",
  initialState,
  reducers: {
    handleShow: (state) => {
      state.show = true
      state.isSuccess = false
    },
    handleClose: (state) => {
      state.show = false
    },
    handleSuccess: (state, action) => {
      state.isSuccess = action.payload
    }
  },
});
export default textPopUpSlice.reducer;
export const textPopUpActions = textPopUpSlice.actions;