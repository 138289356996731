import React from 'react'

const MissionCard = ({ image, title, description, link }) => {
    return (
        <a className="col-md-6" href={link}>
            <div className="m-box">
                <div className="m-left">
                    <span className="w-circle">
                        <img src={image} alt='circle' />
                    </span>
                </div>
                <div className="m-right">
                    <div className="m-title">
                        <h2>{title}</h2>
                    </div>
                    <div className="m-description">
                        <p>
                            {description}
                        </p>
                    </div>
                </div>
            </div>
        </a>
    )
}

export default MissionCard