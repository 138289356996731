import React, { useEffect, useRef, useState } from "react";
import calendar from "../../assets/svg/calender.svg";
import emailjs from "emailjs-com";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';

const PostcardForm = () => {
  const [err, setError] = useState(null);
  const formRef = useRef();
  const [isGraphicArtworkFile, setGraphicArtworkFile] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isMailingListFile, setMailingListFile] = useState(false);
  const [response, setResponse] = useState(null);
  const [graphicPNGImageFileList, setGraphicPNGImageFileList] = useState([]);
  const [graphicPNGImageData, setGraphicPNGImageData] = useState([]);

  const [graphicJPEGImageFileList, setGraphicJPEGImageFileList] = useState([]);
  const [graphicJPEGImageData, setGraphicJPEGImageData] = useState([]);

  const [graphicPDFFileList, setGraphicPDFFileList] = useState([]);
  const [graphicPDFImageData, setGraphicPDFImageData] = useState([]);

  const [mailingCSVImageFileList, setMailingCSVImageFileList] = useState([]);
  const [mailingCSVImageData, setMailingCSVImageData] = useState([]);

  const [mailingTXTFileList, setMailingTXTFileList] = useState([]);
  const [mailingTXTImageData, setMailingTXTImageData] = useState([]);

  const [mailingZIPFileList, setMailingZIPFileList] = useState([]);
  const [mailingZIPImageData, setMailingZIPImageData] = useState([]);

  const [isGraphicFile, setGraphicFile] = useState({ pdf: false, csv: false, png: false, jpeg: false });
  const [isMailingFile, setMailingFile] = useState({ csv: false, png: false, jpeg: false, txt: false, zip: false });
  const [showModal, setShowModal] = useState(false);

  const handleFileChange = (e, type) => {
    const files = e.target.files;
    const newFiles = Array.from(files).slice(0, 6 - (type === 'graphic png' ? graphicPNGImageFileList : type === 'graphic jpeg' ? graphicJPEGImageFileList : type === 'graphic pdf' ? graphicPDFFileList : type === 'mailing csv' ? mailingCSVImageFileList : type === 'mailing txt' ? mailingTXTFileList : type === 'mailing zip' ? mailingZIPFileList : []).length);
    const fileNames = newFiles.map((file) => file.name);
    const setData = type === 'graphic png' ? setGraphicPNGImageData : type === 'graphic jpeg' ? setGraphicJPEGImageData : type === 'graphic pdf' ? setGraphicPDFImageData : type === 'mailing csv' ? setMailingCSVImageData : type === 'mailing txt' ? setMailingTXTImageData : setMailingZIPImageData;
    const setList = type === 'graphic png' ? setGraphicPNGImageFileList : type === 'graphic jpeg' ? setGraphicJPEGImageFileList : type === 'graphic pdf' ? setGraphicPDFFileList : type === 'mailing csv' ? setMailingCSVImageFileList : type === 'mailing txt' ? setMailingTXTFileList : setMailingZIPFileList;
    setData((prevFiles) => [...prevFiles, ...newFiles]);
    setList((prevFiles) => [...prevFiles, ...fileNames]);
    console.log("files here", e.target.files);
  };

  const handleChange = (type, value) => {
    if (type === "graphicArtwork") {
      setGraphicArtworkFile(value);
      if (!value) {
        setGraphicFile({ csv: false, png: false, jpeg: false, pdf: false });
        setGraphicPNGImageData([]);
        setGraphicPNGImageFileList([]);
        setGraphicJPEGImageData([]);
        setGraphicJPEGImageFileList([]);
        setGraphicPDFImageData([]);
        setGraphicPDFFileList([]);
      }
    } else if (type === "mailingList") {
      setMailingListFile(value);
      if (!value) {
        setMailingFile({ csv: false, png: false, jpeg: false, txt: false, zip: false });
        setMailingCSVImageData([]);
        setMailingCSVImageFileList([]);
        setMailingTXTImageData([]);
        setMailingTXTFileList([]);
        setMailingZIPImageData([]);
        setMailingZIPFileList([]);
      }
    } else if (isGraphicArtworkFile) {
      setGraphicFile((prev) => ({ ...prev, [type.split(' ')[1]]: value }));
    } else if (isMailingListFile) {
      setMailingFile((prev) => ({ ...prev, [type.split(' ')[1]]: value }));
    }
  };

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  };

  const resetForm = () => {
    formRef.current.reset();
    setTimeout(() => {
      setResponse(null);
      setPhoneNumber(null);
      setGraphicFile({ pdf: false, csv: false, png: false, jpeg: false });
      setMailingFile({ csv: false, png: false, jpeg: false, txt: false, zip: false });
      setMailingListFile(false);
      setGraphicArtworkFile(false);
      setGraphicPNGImageData([]);
      setGraphicPNGImageFileList([]);
      setMailingCSVImageData([]);
      setMailingCSVImageFileList([]);
      setGraphicJPEGImageData([]);
      setGraphicJPEGImageFileList([]);
      setMailingTXTImageData([]);
      setMailingTXTFileList([]);
      setMailingZIPImageData([]);
      setMailingZIPFileList([]);
    }, 1500);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const sendEmail = async (e) => {
    e.preventDefault();
    let errors = {};
    const fieldNames = {
      fullName: "Full Name",
      phoneNumber: "Phone Number",
      mailAddress: "Email Address",
      businessName: "Business Name",
      Quantity: "Quantity",
      graphicArtwork: "Graphic Artwork",
      mailingList: "Mailing List",
      mailClass: "Mail Class ",
      ProjectMailDate: "Project Mail Date",
      postcardSize: "Postcard Size",
    };
    const requiredFields = [
      "fullName",
      "phoneNumber",
      "mailAddress",
      "businessName",
      "Quantity",
      "graphicArtwork",
      "mailingList",
      "mailClass",
      "ProjectMailDate",
      "postcardSize",
    ];

    for (const field of requiredFields) {
      const value = e.target[field].value.trim();
      if (!value) {
        errors[field] = `Please fill in the ${fieldNames[field]} field.`;
      }
    }
    setError(errors);
    if (Object.keys(errors).length === 0) {
      const formData = {
        fullName: e.target["fullName"].value,
        phoneNumber: e.target["phoneNumber"].value,
        emailAddress: e.target["mailAddress"].value,
        businessName: e.target["businessName"].value,
        quantity: e.target["Quantity"].value,
        graphicArtwork: e.target["graphicArtwork"].value,
        mailingList: e.target["mailingList"].value,
        mailClass: e.target["mailClass"].value,
        projectMailDate: e.target["ProjectMailDate"].value,
        postcardSize: e.target["postcardSize"].value,
        graphicArtworkPNGFile1: graphicPNGImageData[0],
        graphicArtworkPNGFile2: graphicPNGImageData[1],
        graphicArtworkPNGFile3: graphicPNGImageData[2],
        graphicArtworkPNGFile4: graphicPNGImageData[3],
        graphicArtworkPNGFile5: graphicPNGImageData[4],
        graphicArtworkPNGFile6: graphicPNGImageData[5],
        graphicArtworkJPEGFile1: graphicJPEGImageData[0],
        graphicArtworkJPEGFile2: graphicJPEGImageData[1],
        graphicArtworkJPEGFile3: graphicJPEGImageData[2],
        graphicArtworkJPEGFile4: graphicJPEGImageData[3],
        graphicArtworkJPEGFile5: graphicJPEGImageData[4],
        graphicArtworkJPEGFile6: graphicJPEGImageData[5],
        graphicArtworkPDFFile1: graphicPDFImageData[0],
        graphicArtworkPDFFile2: graphicPDFImageData[1],
        graphicArtworkPDFFile3: graphicPDFImageData[2],
        graphicArtworkPDFFile4: graphicPDFImageData[3],
        graphicArtworkPDFFile5: graphicPDFImageData[4],
        graphicArtworkPDFFile6: graphicPDFImageData[5],
        mailingCSVFile1: mailingCSVImageData[0],
        mailingCSVFile2: mailingCSVImageData[1],
        mailingCSVFile3: mailingCSVImageData[2],
        mailingCSVFile4: mailingCSVImageData[3],
        mailingCSVFile5: mailingCSVImageData[4],
        mailingCSVFile6: mailingCSVImageData[5],
        mailingTXTFile1: mailingTXTImageData[0],
        mailingTXTFile2: mailingTXTImageData[1],
        mailingTXTFile3: mailingTXTImageData[2],
        mailingTXTFile4: mailingTXTImageData[3],
        mailingTXTFile5: mailingTXTImageData[4],
        mailingTXTFile6: mailingTXTImageData[5],
        mailingZIPFile1: mailingZIPImageData[0],
        mailingZIPFile2: mailingZIPImageData[1],
        mailingZIPFile3: mailingZIPImageData[2],
        mailingZIPFile4: mailingZIPImageData[3],
        mailingZIPFile5: mailingZIPImageData[4],
        mailingZIPFile6: mailingZIPImageData[5],
        mailingLink: "",
      };

      if (formData.graphicArtwork === "No") {
        formData.graphicArtworkFile = null;
      }
      if (formData.mailingList === "No") {
        formData.mailingLink =
          "https://www.datawidgetcheckout.com/configList.php?e=656e0060049fe9.00712466";
      }
      function readImageDataAsDataURL(imageFile) {
        return new Promise((resolve, reject) => {
          if (!imageFile) {
            resolve(null);
            return;
          }
          const reader = new FileReader();
          reader.onload = (e) => resolve(e.target.result);
          reader.onerror = reject;
          reader.readAsDataURL(imageFile);
        });
      }

      async function processImagesAndSendEmail(graphicPNGImages, graphicJPEGImages, graphicPDFFiles, mailingCSVImages, mailingTXTImages, mailingZIPImages, formData) {
        const graphicPNGPromises = graphicPNGImages.map((image) => readImageDataAsDataURL(image));
        const graphicJPEGPromises = graphicJPEGImages.map((image) => readImageDataAsDataURL(image));
        const graphicPDFPromises = graphicPDFFiles.map((file) => readImageDataAsDataURL(file));
        const mailingCSVPromises = mailingCSVImages.map((image) => readImageDataAsDataURL(image));
        const mailingTXTPromises = mailingTXTImages.map((file) => readImageDataAsDataURL(file));
        const mailingZIPPromises = mailingZIPImages.map((file) => readImageDataAsDataURL(file));

        const results = await Promise.all([...graphicPNGPromises, ...graphicJPEGPromises, ...graphicPDFPromises, ...mailingCSVPromises, ...mailingTXTPromises, ...mailingZIPPromises]);

        const graphicPNGEndIndex = graphicPNGImages.length;
        const graphicJPEGEndIndex = graphicPNGEndIndex + graphicJPEGImages.length;
        const graphicPDFEndIndex = graphicJPEGEndIndex + graphicPDFFiles.length;
        const mailingCSVEndIndex = graphicPDFEndIndex + mailingCSVImages.length;
        const mailingTXTEndIndex = mailingCSVEndIndex + mailingTXTImages.length;
        const mailingZIPEndIndex = mailingTXTEndIndex + mailingZIPImages.length;

        const graphicPNGFiles = results.slice(0, graphicPNGEndIndex);
        const graphicJPEGFiles = results.slice(graphicPNGEndIndex, graphicJPEGEndIndex);
        const graphicPDFFilesProcessed = results.slice(graphicJPEGEndIndex, graphicPDFEndIndex);
        const mailingCSVFiles = results.slice(graphicPDFEndIndex, mailingCSVEndIndex);
        const mailingTXTFfilesProcessed = results.slice(mailingCSVEndIndex, mailingTXTEndIndex);
        const mailingZIPFilesProcessed = results.slice(mailingTXTEndIndex, mailingZIPEndIndex);

        const emailParams = {
          ...formData,
          ...Object.fromEntries(graphicPNGFiles.map((file, index) => [`graphicArtworkPNGFile${index + 1}`, file])),
          ...Object.fromEntries(graphicJPEGFiles.map((file, index) => [`graphicArtworkJPEGFile${index + 1}`, file])),
          ...Object.fromEntries(graphicPDFFilesProcessed.map((file, index) => [`graphicArtworkPDFFile${index + 1}`, file])),
          ...Object.fromEntries(mailingCSVFiles.map((file, index) => [`mailingCSVFile${index + 1}`, file])),
          ...Object.fromEntries(mailingTXTFfilesProcessed.map((file, index) => [`mailingTXTFile${index + 1}`, file])),
          ...Object.fromEntries(mailingZIPFilesProcessed.map((file, index) => [`mailingZIPFile${index + 1}`, file])),
        };

        const serviceID = "service_nu2tww4";
        const templateID = "template_1sv30dd";
        const publicKey = "IqMFFU3Z2aSszwIQ-";

        emailjs.send(serviceID, templateID, emailParams, publicKey)
          .then((response) => {
            setResponse("Email sent successfully");
            resetForm();
          })
          .catch((error) => {
            setResponse(`Failed to send email. ${error.text}`);
            resetForm();
          });
      }

      if (graphicPNGImageData.length || graphicJPEGImageData.length || graphicPDFImageData.length || mailingCSVImageData.length || mailingTXTImageData.length || mailingZIPImageData.length) {
        processImagesAndSendEmail(graphicPNGImageData, graphicJPEGImageData, graphicPDFImageData, mailingCSVImageData, mailingTXTImageData, mailingZIPImageData, formData);
      } else {
        processImagesAndSendEmail([], [], [], [], [], formData);
      }
    }
  };

  const handlePhoneNumberChange = (e) => {
    const inputNumber = e.target.value.replace(/\D/g, '');
    let formattedNumber = formatPhoneNumber(inputNumber);
    if (inputNumber.length > 0) {
      formattedNumber = `(${formattedNumber.slice(0, 3)}) ${formattedNumber.slice(3)}`;
    }

    setPhoneNumber(formattedNumber);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      const inputNumber = phoneNumber.replace(/\D/g, '');
      setPhoneNumber(formatPhoneNumber(inputNumber));
    }
  };

  const formatPhoneNumber = (number) => {
    if (!number) return '';
    const formattedNumber = number.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2-$3');
    return formattedNumber;
  };

  const handleXLFileChange = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <section className="order-form inner-padding" data-aos="fade-left" data-aos-duration="1500">
      <div className="container">
        <div className="order-form-inner">
          {response ? (
            <div className="text-center h3">{response}</div>
          ) : (
            <>
              <h1 style={{ color: "black" }} className="sec_title text-center">
                Tell us about your Postcard project
              </h1>
              <form ref={formRef} onSubmit={sendEmail} encType="multipart/form-data" id="usa-mail-postcard-form" className="usa-mail-postcard-form">
                <div className="b-bottom mb-30">
                  <h4 className="form-title mb-30">Contact Details</h4>
                  <div className="two-feild">
                    <div className="input-feild mb-30">
                      <label>
                        Full Name <abbr>*</abbr>
                      </label>
                      <input type="text" id="full-name" name="fullName" />
                      <label className="inputError">
                        {err?.fullName && err.fullName}
                      </label>
                    </div>
                    <div className="input-feild mb-30">
                      <label>
                        Phone Number <abbr>*</abbr>
                      </label>
                      <input type="text" value={phoneNumber} onKeyDown={handleKeyDown} onChange={handlePhoneNumberChange} id="phone-number" name="phoneNumber" />
                      <label className="inputError">
                        {err?.phoneNumber && err.phoneNumber}
                      </label>
                    </div>
                    <div className="input-feild mb-30">
                      <label>
                        Email Address <abbr>*</abbr>
                      </label>
                      <input type="email" id="mailAddress" name="mailAddress" />
                      <label className="inputError">
                        {err?.mailAddress && err.mailAddress}
                      </label>
                    </div>
                    <div className="input-feild mb-30">
                      <label>
                        Business Name <abbr>*</abbr>
                      </label>
                      <input type="text" id="businessName" name="businessName" />
                      <label className="inputError">
                        {err?.businessName && err.businessName}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="two-feild">
                  <div className="input-feild mb-30">
                    <label htmlFor="Quantity">Quantity</label>
                    <input type="text" id="Quantity" name="Quantity" placeholder="" />
                    <label className="inputError">
                      {err?.Quantity && err.Quantity}
                    </label>
                  </div>
                  <div className="input-feild date-feild mb-30">
                    <label htmlFor="mailDate">Projected Mail Date</label>
                    <div className="position-relative">
                      <input type="date" id="Project-mailDate" name="ProjectMailDate" />
                      <span>
                        <img src={calendar} alt="calender" />
                      </span>
                    </div>
                    <label className="inputError">
                      {err?.ProjectMailDate && err.ProjectMailDate}
                    </label>
                  </div>
                  <div className="input-feild mb-30">
                    <label className="mb-30 font-20">
                      Are you providing Graphic Artwork?
                    </label>
                    <label className="form-container mb-30">
                      Yes
                      <span>You may upload your artwork to us here. <span className="span"> Upload Your Files</span></span>
                      <input type="radio" name="graphicArtwork" value="Yes" onChange={() => handleChange("graphicArtwork", true)} />
                      <span className="checkmark"></span>
                    </label>
                    {isGraphicArtworkFile && (
                      <>
                        <label className="form-container mb-30">
                          Upload PNG Files
                          <input type="checkbox" name="graphicPNG" value="Yes" onChange={(e) => handleChange("graphic png", e.target.checked)} />
                          <span className="checkmark"></span>
                        </label>
                        {isGraphicFile.png && (
                          <>
                            <span className="file-span">Please upload png image</span>
                            <input type="file" name="graphicArtworkFile" className="mb-30" onChange={(e) => handleFileChange(e, 'graphic png')} accept="image/png" />
                            {graphicPNGImageFileList.length > 5 ? (
                              <label className="inputError">You can upload a maximum of 6 images.</label>
                            ) : ""}
                            {graphicPNGImageFileList.length > 0 && (
                              <div className="container my-3 mt-3">
                                <h5>Uploaded PNG Images:</h5>
                                <ul className="list-group">
                                  {graphicPNGImageFileList.map((fileName, index) => (
                                    <li key={index} className="list-group-item">
                                      {fileName}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        )}
                        <label className="form-container mb-30">
                          Upload JPEG Files
                          <input type="checkbox" name="graphicJPEG" value="Yes" onChange={(e) => handleChange("graphic jpeg", e.target.checked)} />
                          <span className="checkmark"></span>
                        </label>
                        {isGraphicFile.jpeg && (
                          <>
                            <span className="file-span">Please upload jpeg image</span>
                            <input type="file" name="graphicArtworkFile" className="mb-30" onChange={(e) => handleFileChange(e, 'graphic jpeg')} accept="image/jpeg" />
                            {graphicJPEGImageFileList.length > 5 ? (
                              <label className="inputError">You can upload a maximum of 6 images.</label>
                            ) : ""}
                            {graphicJPEGImageFileList.length > 0 && (
                              <div className="container my-3 mt-3">
                                <h5>Uploaded JPEG Images:</h5>
                                <ul className="list-group">
                                  {graphicJPEGImageFileList.map((fileName, index) => (
                                    <li key={index} className="list-group-item">
                                      {fileName}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        )}
                        <label className="form-container mb-30">
                          Upload PDF Files
                          <input type="checkbox" name="graphicPDF" value="Yes" onChange={(e) => handleChange("graphic pdf", e.target.checked)} />
                          <span className="checkmark"></span>
                        </label>
                        {isGraphicFile.pdf && (
                          <>
                            <span className="file-span">Please upload pdf file</span>
                            <input type="file" name="graphicArtworkFile" className="mb-30" onChange={(e) => handleFileChange(e, 'graphic pdf')} accept=".pdf" />
                            {graphicPDFFileList.length > 5 && (
                              <label className="inputError">You can upload a maximum of 6 files.</label>
                            )}
                            {graphicPDFFileList.length > 0 && (
                              <div className="container my-3 mt-3">
                                <h5>Uploaded PDF Files:</h5>
                                <ul className="list-group">
                                  {graphicPDFFileList.map((fileName, index) => (
                                    <li key={index} className="list-group-item">
                                      {fileName}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                    <label className="form-container">
                      No
                      <span>Please send me a price for designing my postcard. </span>
                      <input type="radio" name="graphicArtwork" value="No" onChange={() => handleChange("graphicArtwork", false)} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="inputError">
                      {err?.graphicArtwork && err.graphicArtwork}
                    </label>
                  </div>
                  <div className="input-feild mb-30">
                    <label className="mb-30 font-20">
                      Are you providing the Mailing List?
                    </label>
                    <label className="form-container mb-30">
                      Yes
                      <span>You may upload your list to us here.<span className="span">Upload Your Files</span></span>
                      <input type="radio" name="mailingList" value="Yes" onChange={() => handleChange("mailingList", true)} />
                      <span className="checkmark"></span>
                    </label>
                    {isMailingListFile && (
                      <>
                        <label className="form-container mb-30">
                          Upload CSV Files
                          <input type="checkbox" name="mailingCSV" value="Yes" onChange={(e) => handleChange("mailing csv", e.target.checked)} />
                          <span className="checkmark"></span>
                        </label>
                        {isMailingFile.csv && (
                          <>
                            <span className="file-span">Please upload csv file</span>
                            <input type="file" name="mailingListFile" className="mb-30" onChange={(e) => handleFileChange(e, 'mailing csv')} accept=".csv" />
                            {mailingCSVImageFileList.length > 5 && (
                              <label className="inputError">You can upload a maximum of 6 files.</label>
                            )}
                            {mailingCSVImageFileList.length > 0 && (
                              <div className="container my-3 mt-3">
                                <h5>Uploaded CSV Files:</h5>
                                <ul className="list-group">
                                  {mailingCSVImageFileList.map((fileName, index) => (
                                    <li key={index} className="list-group-item">
                                      {fileName}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        )}
                        <label className="form-container mb-30">
                          Upload TXT Files
                          <input type="checkbox" name="mailingTXT" value="Yes" onChange={(e) => handleChange("mailing txt", e.target.checked)} />
                          <span className="checkmark"></span>
                        </label>
                        {isMailingFile.txt && (
                          <>
                            <span className="file-span">Please upload txt file</span>
                            <input type="file" name="mailingListFile" className="mb-30" onChange={(e) => handleFileChange(e, 'mailing txt')} accept=".txt" />
                            {mailingTXTFileList.length > 5 && (
                              <label className="inputError">You can upload a maximum of 6 files.</label>
                            )}
                            {mailingTXTFileList.length > 0 && (
                              <div className="container my-3 mt-3">
                                <h5>Uploaded TXT Files:</h5>
                                <ul className="list-group">
                                  {mailingTXTFileList.map((fileName, index) => (
                                    <li key={index} className="list-group-item">
                                      {fileName}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        )}
                        <label className="form-container mb-30">
                          Upload ZIP Files
                          <input type="checkbox" name="mailingZIP" value="Yes" onChange={(e) => handleChange("mailing zip", e.target.checked)} />
                          <span className="checkmark"></span>
                        </label>
                        {isMailingFile.zip && (
                          <>
                            <span className="file-span">Please upload zip file</span>
                            <input type="file" name="mailingListFile" className="mb-30" onChange={(e) => handleFileChange(e, 'mailing zip')} accept=".zip" />
                            {mailingZIPFileList.length > 5 && (
                              <label className="inputError">You can upload a maximum of 6 files.</label>
                            )}
                            {mailingZIPFileList.length > 0 && (
                              <div className="container my-3 mt-3">
                                <h5>Uploaded ZIP Files:</h5>
                                <ul className="list-group">
                                  {mailingZIPFileList.map((fileName, index) => (
                                    <li key={index} className="list-group-item">
                                      {fileName}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        )}
                        <label className="form-container mb-30">
                          XL Files
                          <input type="checkbox" name="mailingXL" value="Yes" onChange={handleXLFileChange} />
                          <span className="checkmark"></span>
                        </label>
                      </>
                    )}
                    <label className="form-container">
                      No
                      <span>We will help you create your list. </span>
                      <input type="radio" name="mailingList" value="No" onChange={() => handleChange("mailingList", false)} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="inputError">
                      {err?.mailingList && err.mailingList}
                    </label>
                  </div>
                </div>
                <div className="input-feild mb-30 b-bottom">
                  <label className="mb-30 font-20">
                    What class of mail are you sending at?{" "}
                    <span className="gray-clr font-18">(select one)</span>
                  </label>
                  <div className="pia-className">
                    <label className="form-container mb-30">
                      Marketing Mail (Standard)
                      <input type="radio" name="mailClass" value="Marketing Mail" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="form-container mb-30">
                      First Class Mail
                      <input type="radio" name="mailClass" value="First Class Mail" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="form-container">
                      Non-profit Mail
                      <input type="radio" name="mailClass" value="Non-profit Mail" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="inputError">
                      {err?.mailClass && err.mailClass}
                    </label>
                  </div>
                </div>
                <div className="input-feild mb-30 ">
                  <label className="mb-30 font-20">
                    What size postcard are you mailing?
                    <span className="gray-clr font-18">(choose one)</span>
                  </label>
                  <div className="pia-className">
                    <label className="form-container mb-30">
                      4”x 6”
                      <input type="radio" name="postcardSize" value="4”x 6”" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="form-container mb-30">
                      5” x7”
                      <input type="radio" name="postcardSize" value="5” x7”" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="form-container mb-30">
                      6” x 9”
                      <input type="radio" name="postcardSize" value="6” x 9”" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="form-container mb-30">
                      6” x 11”
                      <input type="radio" name="postcardSize" value="6” x 11”" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="form-container">
                      8.5” x 11”
                      <input type="radio" name="postcardSize" value="8.5” x 11”" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="inputError">
                      {err?.postcardSize && err.postcardSize}
                    </label>
                  </div>
                </div>
                <div className="submit-btn">
                  <button type="submit" className="btn r-btn hover-btn wave-btn">
                    Submit
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>XL Files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please send XL files as an email attachment directly to <a href="mailto:info@usamail.direct">info@usamail.direct</a>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default PostcardForm;
