import React, { Fragment, useState } from 'react'
import Header from '../components/Header/Header'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from '../components/Footer/Footer'
import TextPopup from '../components/TextPopup/TextPopup'
import { useSelector } from 'react-redux'
import { LandingPage } from '../components/LandingPage/LandingPage'


export const Root = ({ children }) => {
  const [active, setActive] = useState(null)
  const [visible, setVisible] = useState(false)
  const show = useSelector(state => state.text.show)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true)
    }
    else if (scrolled <= 100) {
      setVisible(false)
    }
  };

  window.addEventListener('scroll', toggleVisible);
  const scrollToTop = () => {
    const delay = 500
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, delay)

  };
  const location = useLocation();
  // return (
  //   <Fragment>
  //     {/* <TextPopup /> */}
  //     {show && <div className='overlay-visible'></div>}
  //     {location.pathname === '/political-mail-marketing' ?
  //       <>
  //         <LandingPage />
  //         <span onClick={scrollToTop} className={`scrollToTop cursor-pointer-scroll-up ${visible ? 'd-flex' : 'd-none'}`}><i className="fas fa fa-arrow-up "></i></span>
  //       </> : <>
  //         <div className={`${show && 'overlay'}`}>
  //           <Header active={active} setActive={setActive} />
  //           <span onClick={scrollToTop} className={`scrollToTop cursor-pointer-scroll-up ${visible ? 'd-flex' : 'd-none'}`}><i className="fas fa fa-arrow-up "></i></span>
  //           <Outlet />
  //           <Footer setActive={setActive} />
  //         </div>
  //       </>}

  //   </Fragment>
  // )
  return (
    <Fragment>
      {/* <TextPopup /> */}
      {show && <div className='overlay-visible'></div>}
      <div className={`${show && 'overlay'}`}>
        <Header active={active} setActive={setActive} />
        <span onClick={scrollToTop} className={`scrollToTop cursor-pointer-scroll-up ${visible ? 'd-flex' : 'd-none'}`}><i className="fas fa fa-arrow-up "></i></span>
        {children}
        <Outlet />
        <Footer setActive={setActive} />
      </div>


    </Fragment>
  )
}