import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { contactData } from '../../../constants/constants';
import ContactCard from '../../commonCards/ContactCard';

export const LandingPageContactUsComponents = () => {


    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        })
    }
    useEffect(() => {
        scrollToTop()
    }, [])

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "USA Mail Direct",
        "alternateName": "USA Mail Direct",
        "url": "https://usamail.direct/",
        "logo": ""
      }
    `}
                </script>
                <title>Contact USA Mail Direct - Direct Mail Service Near Me</title>
                <meta name="description" content="Need a reliable direct mail service? Look no further. Contact USA Mail Direct for fast, efficient, and cost-effective direct mail solutions. Boost your marketing efforts today." />
            </Helmet>
            <main>
                <section className="contact-page inner-padding">
                    <div className="contact-page-inner">
                        <div className="container">
                            <div className="row">
                                {contactData.map(contact => <ContactCard {...contact} />)}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
