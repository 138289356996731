import React, { useEffect } from 'react'
import TitleBannerCard from '../commonCards/TitleBannerCard'
import Testinomials from '../Testimonials/Testimonials'
import RecentArticles from '../Articles/ArticleComponents/RecentArticles'
import About from './AboutUsComponents/About'
import { Helmet } from 'react-helmet'

export const AboutUs = () => {
  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    })
  }
  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "USA Mail Direct",
        "alternateName": "USA Mail Direct",
        "url": "https://usamail.direct/",
        "logo": ""
      }
    `}
        </script>
        <title>About USA Mail Direct - USA Direct Mail Marketing Agency</title>
        <meta name="description" content="USA Mail Direct is a leading direct mail marketing agency that specializes in providing efficient and effective direct mail services. Boost your business with our targeted campaigns!" />
      </Helmet>
      <main>
        <TitleBannerCard title='ABOUT US' className='about-bg' />
        <About />
        <Testinomials />
        <RecentArticles />
      </main>
    </>
  )
}
