import React, { Fragment, useEffect, useState } from "react";
import usaFlag from "../../../assets/images/usa-flag.gif";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import AOS from 'aos';
const Header = ({ active, setActive }) => {
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [aosAnimation, setAosAnimation] = useState('fade-down');
    const [drop, setDrop] = useState(false)
    useEffect(() => {
        AOS.init({
            duration: 1200,
        })
    }, [active])
    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };
    const handleClick = (a) => {
        setActive(a)
        setAosAnimation('fade-down')
        if (sidebarVisible) {
            setSidebarVisible(!sidebarVisible)
        }
    }
    const handleDropdown = () => {
        setDrop(!drop)
    }

    return (
        <Fragment>
            <div className="top-header" data-aos='fade-out'>
                <div className="container">
                    <div className="top-header-wrap">
                        <div className="top-left-header">
                            <h2>Work from anywhere. Send direct mail to anywhere.</h2>
                        </div>
                        <div className="top-right-header">
                            <div className="contact-header">
                                <p>Contact us: Call or Text</p>
                                <div className="telephone-contact">
                                    <Link to="tel:+309 872-6245">
                                        <span className="num-one"> <i className="fa-solid fa-phone"></i> <span className=" num-line">
                                            (309) USA-MAIL</span> </span>

                                    </Link>
                                </div>
                            </div>
                            <div className="flag-head">
                                <img src={usaFlag} alt="usa flag" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
export default Header;




