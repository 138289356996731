import React from 'react'

const LandingPageHeader = () => {
    return (
        <header class="ld-header">
            <div class="container">
                <div class="header-con">
                    <p>America’s Political Mail Professionals</p>
                    <p>Work from your own office... Send Campaign Mail Anywhere.</p>
                    <span>Call or Text <a href="tel: 309-872-6245">(309) 872-6245</a> </span>
                </div>
            </div>
        </header>
    )
}

export default LandingPageHeader