import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap'; // Import necessary components from react-bootstrap
import calendar from '../../assets/svg/calender.svg';
import emailjs from 'emailjs-com';

const FlatSizedMailerForm = () => {
  const [err, setError] = useState(null);
  const formRef = useRef();
  const [isGraphicArtworkFile, setGraphicArtworkFile] = useState(false);
  const [isMailingListFile, setMailingListFile] = useState(false);
  const [response, setResponse] = useState(null);

  const [graphicPNGImageFileList, setGraphicPNGImageFileList] = useState([]);
  const [graphicPNGImageData, setGraphicPNGImageData] = useState([]);

  const [graphicJPEGImageFileList, setGraphicJPEGImageFileList] = useState([]);
  const [graphicJPEGImageData, setGraphicJPEGImageData] = useState([]);

  const [graphicPDFFileList, setGraphicPDFFileList] = useState([]);
  const [graphicPDFData, setGraphicPDFData] = useState([]);

  const [mailingCSVImageFileList, setMailingCSVImageFileList] = useState([]);
  const [mailingCSVImageData, setMailingCSVImageData] = useState([]);

  const [mailingTXTFileList, setMailingTXTFileList] = useState([]);
  const [mailingTXTImageData, setMailingTXTImageData] = useState([]);

  const [mailingZIPFileList, setMailingZIPFileList] = useState([]);
  const [mailingZIPImageData, setMailingZIPImageData] = useState([]);

  const [mailingXLFileList, setMailingXLFileList] = useState([]);
  const [mailingXLImageData, setMailingXLImageData] = useState([]);

  const [isGraphicFile, setGraphicFile] = useState({ csv: false, png: false, jpeg: false, pdf: false });
  const [isMailingFile, setMailingFile] = useState({ csv: false, txt: false, zip: false, xl: false });

  const [showModal, setShowModal] = useState(false); // State to control the modal visibility

  const handleFileChange = (e, type) => {
    const files = e.target.files;
    const newFiles = Array.from(files);

    switch (type) {
      case 'graphic png':
        setGraphicPNGImageData((prevFiles) => [...prevFiles, ...newFiles.slice(0, 6 - graphicPNGImageFileList.length)]);
        setGraphicPNGImageFileList((prevFiles) => [...prevFiles, ...newFiles.map((file) => file.name).slice(0, 6 - graphicPNGImageFileList.length)]);
        break;
      case 'graphic jpeg':
        setGraphicJPEGImageData((prevFiles) => [...prevFiles, ...newFiles.slice(0, 6 - graphicJPEGImageFileList.length)]);
        setGraphicJPEGImageFileList((prevFiles) => [...prevFiles, ...newFiles.map((file) => file.name).slice(0, 6 - graphicJPEGImageFileList.length)]);
        break;
      case 'graphic pdf':
        setGraphicPDFData((prevFiles) => [...prevFiles, ...newFiles.slice(0, 6 - graphicPDFFileList.length)]);
        setGraphicPDFFileList((prevFiles) => [...prevFiles, ...newFiles.map((file) => file.name).slice(0, 6 - graphicPDFFileList.length)]);
        break;
      case 'mailing csv':
        setMailingCSVImageData((prevFiles) => [...prevFiles, ...newFiles.slice(0, 6 - mailingCSVImageFileList.length)]);
        setMailingCSVImageFileList((prevFiles) => [...prevFiles, ...newFiles.map((file) => file.name).slice(0, 6 - mailingCSVImageFileList.length)]);
        break;
      case 'mailing txt':
        setMailingTXTImageData((prevFiles) => [...prevFiles, ...newFiles.slice(0, 6 - mailingTXTFileList.length)]);
        setMailingTXTFileList((prevFiles) => [...prevFiles, ...newFiles.map((file) => file.name).slice(0, 6 - mailingTXTFileList.length)]);
        break;
      case 'mailing zip':
        setMailingZIPImageData((prevFiles) => [...prevFiles, ...newFiles.slice(0, 6 - mailingZIPFileList.length)]);
        setMailingZIPFileList((prevFiles) => [...prevFiles, ...newFiles.map((file) => file.name).slice(0, 6 - mailingZIPFileList.length)]);
        break;
      default:
        break;
    }
  };

  const handleChange = (type, value) => {
    if (type === 'graphicArtwork' && value) {
      setGraphicArtworkFile(true);
    } else if (type === 'graphicArtwork' && !value) {
      setGraphicArtworkFile(false);
      setGraphicFile({ csv: false, png: false, jpeg: false, pdf: false });
      setGraphicPNGImageData([]);
      setGraphicPNGImageFileList([]);
      setGraphicJPEGImageData([]);
      setGraphicJPEGImageFileList([]);
      setGraphicPDFData([]);
      setGraphicPDFFileList([]);
    }

    if (type === 'mailingList' && value) {
      setMailingListFile(true);
    } else if (type === 'mailingList' && !value) {
      setMailingFile({ csv: false, txt: false, zip: false, xl: false });
      setMailingListFile(false);
      setMailingCSVImageData([]);
      setMailingCSVImageFileList([]);
      setMailingTXTImageData([]);
      setMailingTXTFileList([]);
      setMailingZIPImageData([]);
      setMailingZIPFileList([]);
      setMailingXLImageData([]);
      setMailingXLFileList([]);
    }

    if (isGraphicArtworkFile && type.startsWith('graphic')) {
      setGraphicFile((prev) => ({ ...prev, [type.split(' ')[1]]: value }));
    }
    if (isMailingListFile && type.startsWith('mailing')) {
      setMailingFile((prev) => ({ ...prev, [type.split(' ')[1]]: value }));
    }
  };

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
  };

  const resetForm = () => {
    formRef.current.reset();
    setTimeout(() => {
      setResponse(null);
      setPhoneNumber('');
      setGraphicFile({ pdf: false, csv: false, png: false, jpeg: false });
      setMailingFile({ pdf: false, csv: false, txt: false, zip: false, xl: false });
      setMailingListFile(false);
      setGraphicArtworkFile(false);
      setGraphicPNGImageData([]);
      setGraphicPNGImageFileList([]);
      setMailingCSVImageData([]);
      setMailingCSVImageFileList([]);
      setMailingTXTImageData([]);
      setMailingTXTFileList([]);
      setMailingZIPImageData([]);
      setMailingZIPFileList([]);
      setMailingXLImageData([]);
      setMailingXLFileList([]);
    }, 1500);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    let errors = {};

    const fieldNames = {
      fullName: 'Full Name',
      phoneNumber: 'Phone Number',
      mailAddress: 'Email Address',
      businessName: 'Business Name',
      Quantity: 'Quantity',
      graphicArtwork: 'Graphic Artwork',
      mailingList: 'Mailing List',
      mailClass: 'Mail Class',
      ProjectMailDate: 'Project Mail Date',
      flatSizedMailer: 'Flat Sized Mailer',
      pages: 'Pages',
      coated: 'Coated',
      addressWhiteSpace: 'Address White Space',
      finishedXSize: 'Finished X inches',
      finishedYSize: 'Finished Y inches',
    };
    const requiredFields = [
      'fullName',
      'phoneNumber',
      'mailAddress',
      'businessName',
      'Quantity',
      'graphicArtwork',
      'mailingList',
      'mailClass',
      'ProjectMailDate',
      'flatSizedMailer',
      'pages',
      'coated',
      'finishedYSize',
      'finishedXSize',
      'addressWhiteSpace',
    ];

    for (const field of requiredFields) {
      const value = e.target[field].value.trim();
      if (!value) {
        errors[field] = `Please fill in the ${fieldNames[field]} field.`;
      }
    }
    setError(errors);

    if (Object.keys(errors).length === 0) {
      const formData = {
        fullName: e.target['fullName'].value,
        phoneNumber: e.target['phoneNumber'].value,
        emailAddress: e.target['mailAddress'].value,
        businessName: e.target['businessName'].value,
        quantity: e.target['Quantity'].value,
        graphicArtwork: e.target['graphicArtwork'].value,
        mailingList: e.target['mailingList'].value,
        mailClass: e.target['mailClass'].value,
        projectMailDate: e.target['ProjectMailDate'].value,
        flatSizedMailer: e.target['flatSizedMailer'].value,
        pages: e.target['pages'].value,
        coated: e.target['coated'].value,
        finishedXSize: e.target['finishedXSize'].value,
        finishedYSize: e.target['finishedYSize'].value,
        addressWhiteSpace: e.target['addressWhiteSpace'].value,
        graphicArtworkPNGFile1: graphicPNGImageData[0],
        graphicArtworkPNGFile2: graphicPNGImageData[1],
        graphicArtworkPNGFile3: graphicPNGImageData[2],
        graphicArtworkPNGFile4: graphicPNGImageData[3],
        graphicArtworkPNGFile5: graphicPNGImageData[4],
        graphicArtworkPNGFile6: graphicPNGImageData[5],
        graphicArtworkJPEGFile1: graphicJPEGImageData[0],
        graphicArtworkJPEGFile2: graphicJPEGImageData[1],
        graphicArtworkJPEGFile3: graphicJPEGImageData[2],
        graphicArtworkJPEGFile4: graphicJPEGImageData[3],
        graphicArtworkJPEGFile5: graphicJPEGImageData[4],
        graphicArtworkJPEGFile6: graphicJPEGImageData[5],
        graphicArtworkPDFFile1: graphicPDFData[0],
        graphicArtworkPDFFile2: graphicPDFData[1],
        graphicArtworkPDFFile3: graphicPDFData[2],
        graphicArtworkPDFFile4: graphicPDFData[3],
        graphicArtworkPDFFile5: graphicPDFData[4],
        graphicArtworkPDFFile6: graphicPDFData[5],
        mailingCSVFile1: mailingCSVImageData[0],
        mailingCSVFile2: mailingCSVImageData[1],
        mailingCSVFile3: mailingCSVImageData[2],
        mailingCSVFile4: mailingCSVImageData[3],
        mailingCSVFile5: mailingCSVImageData[4],
        mailingCSVFile6: mailingCSVImageData[5],
        mailingTXTFile1: mailingTXTImageData[0],
        mailingTXTFile2: mailingTXTImageData[1],
        mailingTXTFile3: mailingTXTImageData[2],
        mailingTXTFile4: mailingTXTImageData[3],
        mailingTXTFile5: mailingTXTImageData[4],
        mailingTXTFile6: mailingTXTImageData[5],
        mailingZIPFile1: mailingZIPImageData[0],
        mailingZIPFile2: mailingZIPImageData[1],
        mailingZIPFile3: mailingZIPImageData[2],
        mailingZIPFile4: mailingZIPImageData[3],
        mailingZIPFile5: mailingZIPImageData[4],
        mailingZIPFile6: mailingZIPImageData[5],
      };

      if (formData.graphicArtwork === 'No') {
        formData.graphicArtworkFile = null;
      }
      if (formData.mailingList === 'No') {
        formData.mailingLink = 'https://www.datawidgetcheckout.com/configList.php?e=656e0060049fe9.00712466';
      }

      function readImageDataAsDataURL(imageFile) {
        return new Promise((resolve, reject) => {
          if (!imageFile) {
            resolve(null);
            return;
          }
          const reader = new FileReader();
          reader.onload = (e) => resolve(e.target.result);
          reader.onerror = reject;
          reader.readAsDataURL(imageFile);
        });
      }

      async function processImagesAndSendEmail(graphicPNGImages, graphicJPEGImages, graphicPDFFiles, mailingCSVImages, mailingTXTImages, mailingZIPFiles, mailingXLFiles, formData) {
        const graphicPNGPromises = graphicPNGImages.map((image) => readImageDataAsDataURL(image));
        const graphicJPEGPromises = graphicJPEGImages.map((image) => readImageDataAsDataURL(image));
        const graphicPDFPromises = graphicPDFFiles.map((file) => readImageDataAsDataURL(file));
        const mailingCSVPromises = mailingCSVImages.map((image) => readImageDataAsDataURL(image));
        const mailingTXTPromises = mailingTXTImages.map((image) => readImageDataAsDataURL(image));
        const mailingZIPPromises = mailingZIPFiles.map((file) => readImageDataAsDataURL(file));
        const mailingXLPomises = mailingXLFiles.map((file) => readImageDataAsDataURL(file));

        const results = await Promise.all([
          ...graphicPNGPromises,
          ...graphicJPEGPromises,
          ...graphicPDFPromises,
          ...mailingCSVPromises,
          ...mailingTXTPromises,
          ...mailingZIPPromises,
          ...mailingXLPomises,
        ]);

        const graphicPNGEndIndex = graphicPNGImages.length;
        const graphicJPEGEndIndex = graphicPNGEndIndex + graphicJPEGImages.length;
        const graphicPDFEndIndex = graphicJPEGEndIndex + graphicPDFFiles.length;
        const mailingCSVEndIndex = graphicPDFEndIndex + mailingCSVImages.length;
        const mailingTXTEndIndex = mailingCSVEndIndex + mailingTXTImages.length;
        const mailingZIPEndIndex = mailingTXTEndIndex + mailingZIPFiles.length;
        const mailingXLEndIndex = mailingZIPEndIndex + mailingXLFiles.length;

        const graphicPNGFiles = results.slice(0, graphicPNGEndIndex);
        const graphicJPEGFiles = results.slice(graphicPNGEndIndex, graphicJPEGEndIndex);
        const graphicPDFFilesProcessed = results.slice(graphicJPEGEndIndex, graphicPDFEndIndex);
        const mailingCSVFiles = results.slice(graphicPDFEndIndex, mailingCSVEndIndex);
        const mailingTXTFIlesProcessed = results.slice(mailingCSVEndIndex, mailingTXTEndIndex);
        const mailingZIPFilesProcessed = results.slice(mailingTXTEndIndex, mailingZIPEndIndex);
        const mailingXLFilesProcessed = results.slice(mailingZIPEndIndex, mailingXLEndIndex);

        const emailParams = {
          ...formData,
          ...Object.fromEntries(graphicPNGFiles.map((file, index) => [`graphicArtworkPNGFile${index + 1}`, file])),
          ...Object.fromEntries(graphicJPEGFiles.map((file, index) => [`graphicArtworkJPEGFile${index + 1}`, file])),
          ...Object.fromEntries(graphicPDFFilesProcessed.map((file, index) => [`graphicArtworkPDFFile${index + 1}`, file])),
          ...Object.fromEntries(mailingCSVFiles.map((file, index) => [`mailingCSVFile${index + 1}`, file])),
          ...Object.fromEntries(mailingTXTFIlesProcessed.map((file, index) => [`mailingTXTFile${index + 1}`, file])),
          ...Object.fromEntries(mailingZIPFilesProcessed.map((file, index) => [`mailingZIPFile${index + 1}`, file])),
          ...Object.fromEntries(mailingXLFilesProcessed.map((file, index) => [`mailingXLFile${index + 1}`, file])),
        };

        const serviceID = 'service_nu2tww4';
        const templateID = 'template_kelcpok';
        const publicKey = 'IqMFFU3Z2aSszwIQ-';

        emailjs.send(serviceID, templateID, emailParams, publicKey)
          .then((response) => {
            setResponse('Email sent successfully');
            resetForm();
          })
          .catch((error) => {
            setResponse(`Failed to send email. ${error.text}`);
            resetForm();
          });
      }

      if (
        graphicPNGImageData.length ||
        graphicJPEGImageData.length ||
        graphicPDFData.length ||
        mailingCSVImageData.length ||
        mailingTXTImageData.length ||
        mailingZIPImageData.length ||
        mailingXLImageData.length
      ) {
        processImagesAndSendEmail(
          graphicPNGImageData,
          graphicJPEGImageData,
          graphicPDFData,
          mailingCSVImageData,
          mailingTXTImageData,
          mailingZIPImageData,
          mailingXLImageData,
          formData
        );
      } else {
        processImagesAndSendEmail([], [], [], [], [], [], [], formData);
      }
    }
  };

  const handleXLFileChange = (e) => {
    if (e.target.checked) {
      setShowModal(true);
    }
  };

  const [phoneNumber, setPhoneNumber] = useState('');

  const handlePhoneNumberChange = (e) => {
    const inputNumber = e.target.value.replace(/\D/g, '');
    let formattedNumber = formatPhoneNumber(inputNumber);
    if (inputNumber.length > 0) {
      formattedNumber = `(${formattedNumber.slice(0, 3)}) ${formattedNumber.slice(3)}`;
    }

    setPhoneNumber(formattedNumber);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      const inputNumber = phoneNumber.replace(/\D/g, '');
      setPhoneNumber(formatPhoneNumber(inputNumber));
    }
  };

  const formatPhoneNumber = (number) => {
    if (!number) return '';
    const formattedNumber = number.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2-$3');
    return formattedNumber;
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <section className="order-form inner-padding" data-aos="fade-left" data-aos-duration="1500">
      <div className="container">
        <div className="order-form-inner ">
          {response ? (
            <div className="text-center h3">{response}</div>
          ) : (
            <>
              <h1 style={{ color: 'black' }} className="sec_title text-center">
                Tell us about your Flat-Sized Mailers project
              </h1>
              <form
                className="usa-mail-flat-sized-mailers-form"
                id="usa-mail-flat-sized-mailers-form"
                ref={formRef}
                onSubmit={sendEmail}
                enctype="multipart/form-data"
              >
                <div className="b-bottom mb-30">
                  <h4 className="form-title mb-30">Contact Details</h4>
                  <div className="two-feild">
                    <div className="input-feild mb-30">
                      <label>
                        Full Name <abbr>*</abbr>
                      </label>
                      <input type="text" id="full-name" name="fullName" />
                      <label className="inputError">{err?.fullName && err.fullName}</label>
                    </div>

                    <div className="input-feild mb-30">
                      <label>
                        Phone Number <abbr>*</abbr>
                      </label>
                      <input
                        type="text"
                        value={phoneNumber}
                        onKeyDown={handleKeyDown}
                        onChange={handlePhoneNumberChange}
                        id="phone-number"
                        name="phoneNumber"
                      />
                      <label className="inputError">{err?.phoneNumber && err.phoneNumber}</label>
                    </div>

                    <div className="input-feild mb-30">
                      <label>
                        Email Address <abbr>*</abbr>
                      </label>
                      <input type="email" id="mailAddress" name="mailAddress" />
                      <label className="inputError">{err?.mailAddress && err.mailAddress}</label>
                    </div>

                    <div className="input-feild mb-30">
                      <label>
                        Business Name <abbr>*</abbr>
                      </label>
                      <input type="text" id="businessName" name="businessName" />
                      <label className="inputError">{err?.businessName && err.businessName}</label>
                    </div>
                  </div>
                </div>

                <div className="two-feild">
                  <div className="input-feild mb-30">
                    <label for="Quantity">Quantity</label>
                    <input type="text" id="Quantity" name="Quantity" placeholder="" />
                    <label className="inputError">{err?.Quantity && err.Quantity}</label>
                  </div>
                  <div className="input-feild date-feild mb-30">
                    <label for="mailDate">Projected Mail Date</label>
                    <div className="position-relative">
                      <input type="date" id="Project-mailDate" name="ProjectMailDate" />
                      <span>
                        <img src={calendar} alt="calender" />
                      </span>
                    </div>
                    <label className="inputError">{err?.ProjectMailDate && err.ProjectMailDate}</label>
                  </div>
                  <div className="input-feild mb-30">
                    <label className="mb-30 font-20">Are you providing Graphic Artwork?</label>

                    <label className="form-container mb-30">
                      Yes
                      <span>
                        You may upload your artwork to us here. <span className="span">Upload Your Files</span>
                      </span>
                      <input type="radio" name="graphicArtwork" value="Yes" onChange={() => handleChange('graphicArtwork', true)} />
                      <span className="checkmark"></span>
                    </label>
                    {isGraphicArtworkFile && (
                      <>
                        <label className="form-container mb-30">
                          Upload PNG Files
                          <input
                            type="checkbox"
                            name="graphicPNG"
                            value="Yes"
                            onChange={(e) => handleChange('graphic png', e.target.checked)}
                          />
                          <span className="checkmark"></span>
                        </label>
                        {isGraphicFile.png && (
                          <>
                            <span className="file-span">Please upload png image</span>
                            <input
                              type="file"
                              name="graphicArtworkFile"
                              className="mb-30"
                              onChange={(e) => handleFileChange(e, 'graphic png')}
                              accept="image/png"
                            />
                            {graphicPNGImageFileList.length > 5 ? (
                              <label className="inputError">You can upload a maximum of 6 images.</label>
                            ) : (
                              ''
                            )}
                            {graphicPNGImageFileList.length > 0 && (
                              <div className="container my-3 mt-3">
                                <h5>Uploaded PNG Images:</h5>
                                <ul className="list-group">
                                  {graphicPNGImageFileList.map((fileName, index) => (
                                    <li key={index} className="list-group-item">
                                      {fileName}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        )}
                        <label className="form-container mb-30">
                          Upload JPEG Files
                          <input
                            type="checkbox"
                            name="graphicJPEG"
                            value="Yes"
                            onChange={(e) => handleChange('graphic jpeg', e.target.checked)}
                          />
                          <span className="checkmark"></span>
                        </label>
                        {isGraphicFile.jpeg && (
                          <>
                            <span className="file-span">Please upload jpeg image</span>
                            <input
                              type="file"
                              name="graphicArtworkFile"
                              className="mb-30"
                              onChange={(e) => handleFileChange(e, 'graphic jpeg')}
                              accept="image/jpeg"
                            />
                            {graphicJPEGImageFileList.length > 5 ? (
                              <label className="inputError">You can upload a maximum of 6 images.</label>
                            ) : (
                              ''
                            )}
                            {graphicJPEGImageFileList.length > 0 && (
                              <div className="container my-3 mt-3">
                                <h5>Uploaded JPEG Images:</h5>
                                <ul className="list-group">
                                  {graphicJPEGImageFileList.map((fileName, index) => (
                                    <li key={index} className="list-group-item">
                                      {fileName}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        )}
                        <label className="form-container mb-30">
                          Upload .PDF Files
                          <input
                            type="checkbox"
                            name="graphicPDF"
                            value="Yes"
                            onChange={(e) => handleChange('graphic pdf', e.target.checked)}
                          />
                          <span className="checkmark"></span>
                        </label>
                        {isGraphicFile.pdf && (
                          <>
                            <span className="file-span">Please upload .pdf files</span>
                            <input
                              type="file"
                              name="graphicArtworkFile"
                              className="mb-30"
                              onChange={(e) => handleFileChange(e, 'graphic pdf')}
                              accept=".pdf"
                            />
                            {graphicPDFFileList.length > 5 && <label className="inputError">You can upload a maximum of 6 files.</label>}
                            {graphicPDFFileList.length > 0 && (
                              <div className="container my-3 mt-3">
                                <h5>Uploaded PDF Files:</h5>
                                <ul className="list-group">
                                  {graphicPDFFileList.map((fileName, index) => (
                                    <li key={index} className="list-group-item">
                                      {fileName}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                    <label className="form-container">
                      No
                      <span>Please send me a price for designing my postcard.</span>
                      <input type="radio" name="graphicArtwork" value="No" onChange={() => handleChange('graphicArtwork', false)} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="inputError">{err?.graphicArtwork && err.graphicArtwork}</label>
                  </div>
                  <div className="input-feild mb-30">
                    <label className="mb-30 font-20">Are you providing the Mailing List?</label>
                    <label className="form-container mb-30">
                      Yes
                      <span>
                        You may upload your list to us here.<span className="span">Upload Your Files</span>
                      </span>
                      <input type="radio" name="mailingList" value="Yes" onChange={() => handleChange('mailingList', true)} />
                      <span className="checkmark"></span>
                    </label>
                    {isMailingListFile && (
                      <>
                        <label className="form-container mb-30">
                          Upload CSV Files
                          <input
                            type="checkbox"
                            name="mailingCSV"
                            value="Yes"
                            onChange={(e) => handleChange('mailing csv', e.target.checked)}
                          />
                          <span className="checkmark"></span>
                        </label>
                        {isMailingFile.csv && (
                          <>
                            <span className="file-span">Please upload CSV files</span>
                            <input
                              type="file"
                              name="mailingListFile"
                              className="mb-30"
                              onChange={(e) => handleFileChange(e, 'mailing csv')}
                              accept=".csv"
                            />
                            {mailingCSVImageFileList.length > 5 ? (
                              <label className="inputError">You can upload a maximum of 6 files.</label>
                            ) : (
                              ''
                            )}
                            {mailingCSVImageFileList.length > 0 && (
                              <div className="container my-3 mt-3">
                                <h5>Uploaded CSV Files:</h5>
                                <ul className="list-group">
                                  {mailingCSVImageFileList.map((fileName, index) => (
                                    <li key={index} className="list-group-item">
                                      {fileName}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        )}
                        <label className="form-container mb-30">
                          Upload TXT Files
                          <input
                            type="checkbox"
                            name="mailingTXT"
                            value="Yes"
                            onChange={(e) => handleChange('mailing txt', e.target.checked)}
                          />
                          <span className="checkmark"></span>
                        </label>
                        {isMailingFile.txt && (
                          <>
                            <span className="file-span">Please upload TXT files</span>
                            <input
                              type="file"
                              name="mailingListFile"
                              className="mb-30"
                              onChange={(e) => handleFileChange(e, 'mailing txt')}
                              accept=".txt"
                            />
                            {mailingTXTFileList.length > 5 ? (
                              <label className="inputError">You can upload a maximum of 6 files.</label>
                            ) : (
                              ''
                            )}
                            {mailingTXTFileList.length > 0 && (
                              <div className="container my-3 mt-3">
                                <h5>Uploaded TXT Files:</h5>
                                <ul className="list-group">
                                  {mailingTXTFileList.map((fileName, index) => (
                                    <li key={index} className="list-group-item">
                                      {fileName}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        )}
                        <label className="form-container mb-30">
                          Upload ZIP Files
                          <input
                            type="checkbox"
                            name="mailingZIP"
                            value="Yes"
                            onChange={(e) => handleChange('mailing zip', e.target.checked)}
                          />
                          <span className="checkmark"></span>
                        </label>
                        {isMailingFile.zip && (
                          <>
                            <span className="file-span">Please upload ZIP files</span>
                            <input
                              type="file"
                              name="mailingListFile"
                              className="mb-30"
                              onChange={(e) => handleFileChange(e, 'mailing zip')}
                              accept=".zip"
                            />
                            {mailingZIPFileList.length > 5 ? (
                              <label className="inputError">You can upload a maximum of 6 files.</label>
                            ) : (
                              ''
                            )}
                            {mailingZIPFileList.length > 0 && (
                              <div className="container my-3 mt-3">
                                <h5>Uploaded ZIP Files:</h5>
                                <ul className="list-group">
                                  {mailingZIPFileList.map((fileName, index) => (
                                    <li key={index} className="list-group-item">
                                      {fileName}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        )}
                        <label className="form-container mb-30">
                          Upload XL Files
                          <input
                            type="checkbox"
                            name="mailingXL"
                            value="Yes"
                            onChange={(e) => handleXLFileChange(e)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </>
                    )}
                    <label className="form-container">
                      No
                      <span>We will help you create your list.</span>
                      <input type="radio" name="mailingList" value="No" onChange={() => handleChange('mailingList', false)} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="inputError">{err?.mailingList && err.mailingList}</label>
                  </div>
                </div>

                <div className="input-feild mb-30">
                  <label className="mb-30 font-20">
                    What class of mail are you sending at? <span className="gray-clr font-18">(select one)</span>
                  </label>
                  <div className="pia-className">
                    <label className="form-container mb-30">
                      Marketing Mail(Standard)
                      <input type="radio" name="mailClass" value="Marketing Mail" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="form-container mb-30">
                      First Class Mail
                      <input type="radio" name="mailClass" value="First Class Mail" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="form-container mb-30">
                      Non-profit Mail
                      <input type="radio" name="mailClass" value="Non-profit Mail" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="form-container">
                      Periodicals
                      <input type="radio" name="mailClass" value="Periodicals" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="inputError">{err?.mailClass && err.mailClass}</label>
                  </div>
                </div>
                <div className="input-feild mb-30">
                  <label className="mb-30 font-20">
                    What is the Flat-Sized Mailer? <span className="gray-clr font-18">(select one)</span>
                  </label>
                  <div className="pia-className">
                    <label className="form-container mb-30">
                      Newsletters
                      <input type="radio" name="flatSizedMailer" value="Newsletters" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="form-container mb-30">
                      Magazines
                      <input type="radio" name="flatSizedMailer" value="Magazines" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="form-container">
                      Catalogs
                      <input type="radio" name="flatSizedMailer" value="Catalogs" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="inputError">{err?.flatSizedMailer && err.flatSizedMailer}</label>
                  </div>
                </div>
                <div className="input-feild mb-30">
                  <label>
                    How many pages?<abbr>*</abbr>
                  </label>
                  <input type="number" id="pages" name="pages" />
                  <label className="inputError">{err?.pages && err.pages}</label>
                </div>
                <div className="input-feild mb-30">
                  <label className="mb-30 font-20">
                    Is it printed on Coated (glossy) or Uncoated (regular) stock?{' '}
                  </label>
                  <div className="pia-className">
                    <label className="form-container mb-30">
                      Coated
                      <input type="radio" name="coated" value="Coated" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="form-container mb-30">
                      Uncoated
                      <input type="radio" name="coated" value="Uncoated" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="inputError">{err?.coated && err.coated}</label>
                  </div>
                </div>
                <div className="input-feild mb-30">
                  <div className="textInput mb-30">
                    <p className="gray-clr mb-30">
                      What is its finished size when it goes into the mail?
                      <span className="Ti-feild">
                        <input type="text" placeholder="" name="finishedXSize" />
                        inches by &nbsp;
                        <input type="text" placeholder="" name="finishedYSize" />
                        inches &nbsp;
                      </span>
                      <label className="inputError">{err?.finishedXSize && err.finishedXSize}</label>
                      <label className="inputError">{err?.finishedYSize && err.finishedYSize}</label>
                    </p>
                  </div>
                </div>
                <div className="input-feild mb-30">
                  <label className="mb-30 font-20">Does it have blank white space on the outside to print the address in?</label>
                  <div className="pia-className">
                    <label className="form-container mb-30">
                      Yes
                      <input type="radio" name="addressWhiteSpace" value="Yes" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="form-container mb-30">
                      No
                      <input type="radio" name="addressWhiteSpace" value="No" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="inputError">{err?.addressWhiteSpace && err.addressWhiteSpace}</label>
                  </div>
                </div>

                <div className="submit-btn">
                  <button type="submit" className="btn r-btn hover-btn wave-btn">
                    Submit
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
      {/* Modal for XL file uploads */}
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>XL Files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please send XL files as an email attachment directly to <a href="mailto:info@usamail.direct">info@usamail.direct</a>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default FlatSizedMailerForm;
