import React from 'react'
import imagePrem from '../../../assets/images/prem-img.png'

function DiscountPage() {
    return (
        <>
            <section className="mail-exp">
                <div className="mail-head">
                    <h2>Political Mail Experts</h2>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7" data-aos="fade-up">
                            <p className="mail-exp-con">Let the professionals at <a href="#">www.<span className="red-c">U</span><span className="white-c">S</span><span className="blue-c">A</span>Mail<span className="red-c">.Direct</span></a> process
                                your campaign mail this election season!</p>
                        </div>
                        <div className="col-md-5">
                            <div className="prem-serv" data-aos="zoom-in">
                                <img src={imagePrem} alt />
                                <span>Maximum Postage Discounts</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mail-head" data-aos="fade-up">
                    <h3><span>Call or text <a href='tel: 309-872-6245'>(309) 872-6245 Email</a> </span> <span><a href="mailto:info@usamail.direct">info@usamail.direct</a></span></h3>
                </div>
            </section>
            <section class="get-star-sec">
                <div class="get-star-top" data-aos="fade-up">
                    <h3>Let’s Get Started: <span>Tell us about your project.</span></h3>
                </div>

            </section>
        </>
    )
}

export default DiscountPage