import React, { useEffect, useRef, useState } from 'react';
import calendar from '../../assets/svg/calender.svg';
import emailjs from 'emailjs-com';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';

const LettersInEnvelopeForm = () => {
  const [err, setError] = useState(null);
  const formRef = useRef();
  const [isGraphicArtworkFile, setGraphicArtworkFile] = useState(false);
  const [response, setResponse] = useState(null);
  const [isMailingListFile, setMailingListFile] = useState(false);
  const [graphicPNGImageFileList, setGraphicPNGImageFileList] = useState([]);
  const [graphicPNGImageData, setGraphicPNGImageData] = useState([]);
  const [graphicJPEGImageFileList, setGraphicJPEGImageFileList] = useState([]);
  const [graphicJPEGImageData, setGraphicJPEGImageData] = useState([]);
  const [graphicPDFFileList, setGraphicPDFFileList] = useState([]);
  const [graphicPDFData, setGraphicPDFData] = useState([]);
  const [mailingCSVImageFileList, setMailingCSVImageFileList] = useState([]);
  const [mailingCSVImageData, setMailingCSVImageData] = useState([]);
  const [mailingTXTFileList, setMailingTXTFileList] = useState([]);
  const [mailingTXTImageData, setMailingTXTImageData] = useState([]);
  const [mailingZIPFileList, setMailingZIPFileList] = useState([]);
  const [mailingZIPImageData, setMailingZIPImageData] = useState([]);
  const [isGraphicFile, setGraphicFile] = useState({ pdf: false, csv: false, png: false, jpeg: false });
  const [isMailingFile, setMailingFile] = useState({ csv: false, png: false, jpeg: false, txt: false, zip: false });
  const [showModal, setShowModal] = useState(false);

  const handleFileChange = (e, type) => {
    const files = e.target.files;
    const newFiles = Array.from(files);

    switch (type) {
      case 'graphic png':
        setGraphicPNGImageData((prevFiles) => [...prevFiles, ...newFiles.slice(0, 6 - graphicPNGImageFileList.length)]);
        setGraphicPNGImageFileList((prevFiles) => [...prevFiles, ...newFiles.map(file => file.name).slice(0, 6 - graphicPNGImageFileList.length)]);
        break;
      case 'graphic jpeg':
        setGraphicJPEGImageData((prevFiles) => [...prevFiles, ...newFiles.slice(0, 6 - graphicJPEGImageFileList.length)]);
        setGraphicJPEGImageFileList((prevFiles) => [...prevFiles, ...newFiles.map(file => file.name).slice(0, 6 - graphicJPEGImageFileList.length)]);
        break;
      case 'graphic pdf':
        setGraphicPDFData((prevFiles) => [...prevFiles, ...newFiles.slice(0, 6 - graphicPDFFileList.length)]);
        setGraphicPDFFileList((prevFiles) => [...prevFiles, ...newFiles.map(file => file.name).slice(0, 6 - graphicPDFFileList.length)]);
        break;
      case 'mailing csv':
        setMailingCSVImageData((prevFiles) => [...prevFiles, ...newFiles.slice(0, 6 - mailingCSVImageFileList.length)]);
        setMailingCSVImageFileList((prevFiles) => [...prevFiles, ...newFiles.map(file => file.name).slice(0, 6 - mailingCSVImageFileList.length)]);
        break;
      case 'mailing txt':
        setMailingTXTImageData((prevFiles) => [...prevFiles, ...newFiles.slice(0, 6 - mailingTXTFileList.length)]);
        setMailingTXTFileList((prevFiles) => [...prevFiles, ...newFiles.map(file => file.name).slice(0, 6 - mailingTXTFileList.length)]);
        break;
      case 'mailing zip':
        setMailingZIPImageData((prevFiles) => [...prevFiles, ...newFiles.slice(0, 6 - mailingZIPFileList.length)]);
        setMailingZIPFileList((prevFiles) => [...prevFiles, ...newFiles.map(file => file.name).slice(0, 6 - mailingZIPFileList.length)]);
        break;
      default:
        break;
    }
  };

  const handleChange = (type, value) => {
    if (type === "graphicArtwork" && value) {
      setGraphicArtworkFile(true);
    } else if (type === "graphicArtwork" && !value) {
      setGraphicArtworkFile(false);
      setGraphicFile({ csv: false, png: false, jpeg: false, pdf: false });
      setGraphicPNGImageData([]);
      setGraphicPNGImageFileList([]);
      setGraphicJPEGImageData([]);
      setGraphicJPEGImageFileList([]);
      setGraphicPDFData([]);
      setGraphicPDFFileList([]);
    }

    if (type === "mailingList" && value) {
      setMailingListFile(true);
    } else if (type === "mailingList" && !value) {
      setMailingFile({ csv: false, txt: false, zip: false });
      setMailingListFile(false);
      setMailingCSVImageData([]);
      setMailingCSVImageFileList([]);
      setMailingTXTImageData([]);
      setMailingTXTFileList([]);
      setMailingZIPImageData([]);
      setMailingZIPFileList([]);
    }

    if (isGraphicArtworkFile && type.startsWith("graphic")) {
      setGraphicFile(prev => ({ ...prev, [type.split(' ')[1]]: value }));
    }
    if (isMailingListFile && type.startsWith("mailing")) {
      setMailingFile(prev => ({ ...prev, [type.split(' ')[1]]: value }));
    }
  };

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  };

  const resetForm = () => {
    formRef.current.reset();
    setTimeout(() => {
      setResponse(null);
      setPhoneNumber('');
      setGraphicFile({ pdf: false, csv: false, png: false, jpeg: false });
      setMailingFile({ csv: false, png: false, jpeg: false, txt: false, zip: false });
      setMailingListFile(false);
      setGraphicArtworkFile(false);
      setGraphicPNGImageData([]);
      setGraphicPNGImageFileList([]);
      setMailingCSVImageData([]);
      setMailingCSVImageFileList([]);
      setMailingTXTImageData([]);
      setMailingTXTFileList([]);
      setMailingZIPImageData([]);
      setMailingZIPFileList([]);
    }, 1500);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const sendEmail = async (e) => {
    e.preventDefault();
    let errors = {};

    const fieldNames = {
      fullName: 'Full Name',
      phoneNumber: 'Phone Number',
      mailAddress: 'Email Address',
      businessName: 'Business Name',
      Quantity: 'Quantity',
      graphicArtwork: 'Graphic Artwork',
      mailingList: 'Mailing List',
      mailClass: 'Mail Class',
      ProjectMailDate: 'Project Mail Date',
      carrierEnvelope: 'Carrier Envelope',
      envelopeArtwork: 'Envelope Artwork',
      isCoverLetter: 'Cover Letter',
      coverLetterType: 'Cover Letter Type',
      coverLetterArtwork: 'Cover Letter Artwork',
      additionalInserts: 'Additional Inserts',
      totalInserts: 'Total Inserts',
      describeInserts: 'Describe Inserts'
    };
    const requiredFields = ['fullName', 'phoneNumber', 'mailAddress', 'businessName', 'Quantity', 'graphicArtwork', 'mailingList', 'mailClass', 'ProjectMailDate', 'carrierEnvelope', 'envelopeArtwork', 'isCoverLetter', 'coverLetterType', 'coverLetterArtwork', 'additionalInserts', 'totalInserts', 'describeInserts'];

    for (const field of requiredFields) {
      const value = e.target[field].value.trim();
      if (!value) {
        errors[field] = `Please fill in the ${fieldNames[field]} field.`;
      }
    }
    setError(errors);

    if (Object.keys(errors).length === 0) {
      const formData = {
        fullName: e.target['fullName'].value,
        phoneNumber: e.target['phoneNumber'].value,
        emailAddress: e.target['mailAddress'].value,
        businessName: e.target['businessName'].value,
        quantity: e.target['Quantity'].value,
        graphicArtwork: e.target['graphicArtwork'].value,
        mailingList: e.target['mailingList'].value,
        mailClass: e.target['mailClass'].value,
        projectMailDate: e.target['ProjectMailDate'].value,
        carrierEnvelope: e.target['carrierEnvelope'].value,
        envelopeArtwork: e.target['envelopeArtwork'].value,
        isCoverLetter: e.target['isCoverLetter'].value,
        coverLetterType: e.target['coverLetterType'].value,
        coverLetterArtwork: e.target['coverLetterArtwork'].value,
        additionalInserts: e.target['additionalInserts'].value,
        totalInserts: e.target['totalInserts'].value,
        graphicArtworkPNGFile1: graphicPNGImageData[0],
        graphicArtworkPNGFile2: graphicPNGImageData[1],
        graphicArtworkPNGFile3: graphicPNGImageData[2],
        graphicArtworkPNGFile4: graphicPNGImageData[3],
        graphicArtworkPNGFile5: graphicPNGImageData[4],
        graphicArtworkPNGFile6: graphicPNGImageData[5],

        graphicArtworkJPEGFile1: graphicJPEGImageData[0],
        graphicArtworkJPEGFile2: graphicJPEGImageData[1],
        graphicArtworkJPEGFile3: graphicJPEGImageData[2],
        graphicArtworkJPEGFile4: graphicJPEGImageData[3],
        graphicArtworkJPEGFile5: graphicJPEGImageData[4],
        graphicArtworkJPEGFile6: graphicJPEGImageData[5],

        graphicArtworkPDFFile1: graphicPDFData[0],
        graphicArtworkPDFFile2: graphicPDFData[1],
        graphicArtworkPDFFile3: graphicPDFData[2],
        graphicArtworkPDFFile4: graphicPDFData[3],
        graphicArtworkPDFFile5: graphicPDFData[4],
        graphicArtworkPDFFile6: graphicPDFData[5],

        mailingCSVFile1: mailingCSVImageData[0],
        mailingCSVFile2: mailingCSVImageData[1],
        mailingCSVFile3: mailingCSVImageData[2],
        mailingCSVFile4: mailingCSVImageData[3],
        mailingCSVFile5: mailingCSVImageData[4],
        mailingCSVFile6: mailingCSVImageData[5],

        mailingTXTFile1: mailingTXTImageData[0],
        mailingTXTFile2: mailingTXTImageData[1],
        mailingTXTFile3: mailingTXTImageData[2],
        mailingTXTFile4: mailingTXTImageData[3],
        mailingTXTFile5: mailingTXTImageData[4],
        mailingTXTFile6: mailingTXTImageData[5],

        mailingZIPFile1: mailingZIPImageData[0],
        mailingZIPFile2: mailingZIPImageData[1],
        mailingZIPFile3: mailingZIPImageData[2],
        mailingZIPFile4: mailingZIPImageData[3],
        mailingZIPFile5: mailingZIPImageData[4],
        mailingZIPFile6: mailingZIPImageData[5],

        mailingLink: '',
        describeInserts: e.target['describeInserts'].value,
      };

      if (formData.graphicArtwork === 'No') {
        formData.graphicArtworkFile = null;
      }
      if (formData.mailingList === 'No') {
        formData.mailingLink = "https://www.datawidgetcheckout.com/configList.php?e=656e0060049fe9.00712466";
      }

      const readImageDataAsDataURL = (imageFile) => {
        return new Promise((resolve, reject) => {
          if (!imageFile) {
            resolve(null);
            return;
          }
          const reader = new FileReader();
          reader.onload = (e) => resolve(e.target.result);
          reader.onerror = reject;
          reader.readAsDataURL(imageFile);
        });
      };

      const processImagesAndSendEmail = async (graphicPNGImages, graphicJPEGImages, graphicPDFFiles, mailingCSVImages, mailingTXTImages, mailingZIPFiles, formData) => {
        const graphicPNGPromises = graphicPNGImages.map(image => readImageDataAsDataURL(image));
        const graphicJPEGPromises = graphicJPEGImages.map(image => readImageDataAsDataURL(image));
        const graphicPDFPromises = graphicPDFFiles.map(file => readImageDataAsDataURL(file));
        const mailingCSVPromises = mailingCSVImages.map(image => readImageDataAsDataURL(image));
        const mailingTXTPromises = mailingTXTImages.map(image => readImageDataAsDataURL(image));
        const mailingZIPPromises = mailingZIPFiles.map(file => readImageDataAsDataURL(file));

        const results = await Promise.all([...graphicPNGPromises, ...graphicJPEGPromises, ...graphicPDFPromises, ...mailingCSVPromises, ...mailingTXTPromises, ...mailingZIPPromises]);

        const graphicPNGEndIndex = graphicPNGImages.length;
        const graphicJPEGEndIndex = graphicPNGEndIndex + graphicJPEGImages.length;
        const graphicPDFEndIndex = graphicJPEGEndIndex + graphicPDFFiles.length;
        const mailingCSVEndIndex = graphicPDFEndIndex + mailingCSVImages.length;
        const mailingTXTEndIndex = mailingCSVEndIndex + mailingTXTImages.length;
        const mailingZIPEndIndex = mailingTXTEndIndex + mailingZIPFiles.length;

        const graphicPNGFiles = results.slice(0, graphicPNGEndIndex);
        const graphicJPEGFiles = results.slice(graphicPNGEndIndex, graphicJPEGEndIndex);
        const graphicPDFFilesProcessed = results.slice(graphicJPEGEndIndex, graphicPDFEndIndex);
        const mailingCSVFiles = results.slice(graphicPDFEndIndex, mailingCSVEndIndex);
        const mailingTXTFIlesProcessed = results.slice(mailingCSVEndIndex, mailingTXTEndIndex);
        const mailingZIPFilesProcessed = results.slice(mailingTXTEndIndex, mailingZIPEndIndex);

        const emailParams = {
          ...formData,
          ...Object.fromEntries(graphicPNGFiles.map((file, index) => [`graphicArtworkPNGFile${index + 1}`, file])),
          ...Object.fromEntries(graphicJPEGFiles.map((file, index) => [`graphicArtworkJPEGFile${index + 1}`, file])),
          ...Object.fromEntries(graphicPDFFilesProcessed.map((file, index) => [`graphicArtworkPDFFile${index + 1}`, file])),
          ...Object.fromEntries(mailingCSVFiles.map((file, index) => [`mailingCSVFile${index + 1}`, file])),
          ...Object.fromEntries(mailingTXTFIlesProcessed.map((file, index) => [`mailingTXTFile${index + 1}`, file])),
          ...Object.fromEntries(mailingZIPFilesProcessed.map((file, index) => [`mailingZIPFile${index + 1}`, file])),
        };

        const serviceID = "service_nu2tww4";
        const templateID = "template_087e3f1";
        const publicKey = "IqMFFU3Z2aSszwIQ-";

        emailjs.send(serviceID, templateID, emailParams, publicKey)
          .then((response) => {
            setResponse("Email sent successfully");
            resetForm();
          })
          .catch((error) => {
            setResponse(`Failed to send email. ${error.text}`);
            resetForm();
          });
      };

      if (graphicPNGImageData.length || graphicJPEGImageData.length || graphicPDFData.length || mailingCSVImageData.length || mailingTXTImageData.length || mailingZIPImageData.length) {
        processImagesAndSendEmail(graphicPNGImageData, graphicJPEGImageData, graphicPDFData, mailingCSVImageData, mailingTXTImageData, mailingZIPImageData, formData);
      } else {
        processImagesAndSendEmail([], [], [], [], [], [], formData);
      }
    }
  };

  const [phoneNumber, setPhoneNumber] = useState('');

  const handlePhoneNumberChange = (e) => {
    const inputNumber = e.target.value.replace(/\D/g, '');
    let formattedNumber = formatPhoneNumber(inputNumber);
    if (inputNumber.length > 0) {
      formattedNumber = `(${formattedNumber.slice(0, 3)}) ${formattedNumber.slice(3)}`;
    }

    setPhoneNumber(formattedNumber);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      const inputNumber = phoneNumber.replace(/\D/g, '');
      setPhoneNumber(formatPhoneNumber(inputNumber));
    }
  };

  const formatPhoneNumber = (number) => {
    if (!number) return '';
    const formattedNumber = number.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2-$3');
    return formattedNumber;
  };

  const handleXLFileChange = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <section className="order-form inner-padding" data-aos="fade-left" data-aos-duration="1500">
      <div className="container">
        <div className="order-form-inner ">
          {response ? <div className='text-center h3'>{response}</div> : <>
            <h1 style={{ color: "black" }} className="sec_title text-center">Tell us about your Letters in Envelope project</h1>
            <form className="usa-mail-letters-in-envelope-form" id="usa-mail-letters-in-envelope-form" ref={formRef} onSubmit={sendEmail} encType="multipart/form-data">
              <div className="b-bottom mb-30">
                <h4 className="form-title mb-30">Contact Details</h4>
                <div className="two-feild">
                  <div className="input-feild mb-30">
                    <label>Full Name <abbr>*</abbr></label>
                    <input type="text" id="full-name" name="fullName" />
                    <label className='inputError'>{err?.fullName && err.fullName}</label>
                  </div>

                  <div className="input-feild mb-30">
                    <label>Phone Number <abbr>*</abbr></label>
                    <input type="text" value={phoneNumber} onKeyDown={handleKeyDown} onChange={handlePhoneNumberChange} id="phone-number" name="phoneNumber" />
                    <label className="inputError">{err?.phoneNumber && err.phoneNumber}</label>
                  </div>

                  <div className="input-feild mb-30">
                    <label>Email Address <abbr>*</abbr></label>
                    <input type="email" id="mailAddress" name="mailAddress" />
                    <label className='inputError'>{err?.mailAddress && err.mailAddress}</label>
                  </div>

                  <div className="input-feild mb-30">
                    <label>Business Name <abbr>*</abbr></label>
                    <input type="text" id="businessName" name="businessName" />
                    <label className='inputError'>{err?.businessName && err.businessName}</label>
                  </div>
                </div>
              </div>

              <div className="two-feild">
                <div className="input-feild mb-30">
                  <label htmlFor="Quantity">Quantity</label>
                  <input type="text" id="Quantity" name="Quantity" placeholder='' />
                  <label className='inputError'>{err?.Quantity && err.Quantity}</label>
                </div>
                <div className="input-feild date-feild mb-30">
                  <label htmlFor="mailDate">Projected Mail Date</label>
                  <div className=" position-relative">
                    <input type="date" id="Project-mailDate" name="ProjectMailDate" />
                    <span><img src={calendar} alt="calender" /></span>
                  </div>
                  <label className='inputError'>{err?.ProjectMailDate && err.ProjectMailDate}</label>
                </div>
                <div className="input-feild mb-30">
                  <label className="mb-30 font-20">Are you providing Graphic Artwork?</label>

                  <label className="form-container mb-30">
                    Yes
                    <span>You may upload your artwork to us here. <span className='span'> Upload Your Files</span></span>
                    <input type="radio" name="graphicArtwork" value="Yes" onChange={() => handleChange("graphicArtwork", true)} />
                    <span className="checkmark"></span>
                  </label>
                  {isGraphicArtworkFile && (
                    <>
                      <label className="form-container mb-30">Upload PNG Files
                        <input type="checkbox" name="graphicPNG" value="Yes" onChange={(e) => handleChange("graphic png", e.target.checked)} />
                        <span className="checkmark"></span>
                      </label>
                      {isGraphicFile.png && (
                        <>
                          <span className="file-span">Please upload png image</span>
                          <input type="file" name="graphicArtworkFile" className="mb-30" onChange={(e) => handleFileChange(e, 'graphic png')} accept="image/png" />
                          {graphicPNGImageFileList.length > 5 ? <label className="inputError">You can upload a maximum of 6 images.</label> : ""}
                          {graphicPNGImageFileList.length > 0 && (
                            <div className="container my-3 mt-3">
                              <h5>Uploaded PNG Images:</h5>
                              <ul className="list-group">
                                {graphicPNGImageFileList.map((fileName, index) => (<li key={index} className="list-group-item">{fileName}</li>))}
                              </ul>
                            </div>
                          )}
                        </>
                      )}
                      <label className="form-container mb-30">Upload JPEG Files
                        <input type="checkbox" name="graphicJPEG" value="Yes" onChange={(e) => handleChange("graphic jpeg", e.target.checked)} />
                        <span className="checkmark"></span>
                      </label>
                      {isGraphicFile.jpeg && (
                        <>
                          <span className="file-span">Please upload jpeg image</span>
                          <input type="file" name="graphicArtworkFile" className="mb-30" onChange={(e) => handleFileChange(e, 'graphic jpeg')} accept="image/jpeg" />
                          {graphicJPEGImageFileList.length > 5 ? <label className="inputError">You can upload a maximum of 6 images.</label> : ""}
                          {graphicJPEGImageFileList.length > 0 && (
                            <div className="container my-3 mt-3">
                              <h5>Uploaded JPEG Images:</h5>
                              <ul className="list-group">
                                {graphicJPEGImageFileList.map((fileName, index) => (<li key={index} className="list-group-item">{fileName}</li>))}
                              </ul>
                            </div>
                          )}
                        </>
                      )}
                      <label className="form-container mb-30">Upload PDF Files
                        <input type="checkbox" name="graphicPDF" value="Yes" onChange={(e) => handleChange("graphic pdf", e.target.checked)} />
                        <span className="checkmark"></span>
                      </label>
                      {isGraphicFile.pdf && (
                        <>
                          <span className="file-span">Please upload pdf files</span>
                          <input type="file" name="graphicArtworkFile" className="mb-30" onChange={(e) => handleFileChange(e, 'graphic pdf')} accept=".pdf" />
                          {graphicPDFFileList.length > 5 && <label className="inputError">You can upload a maximum of 6 files.</label>}
                          {graphicPDFFileList.length > 0 && (
                            <div className="container my-3 mt-3">
                              <h5>Uploaded PDF Files:</h5>
                              <ul className="list-group">
                                {graphicPDFFileList.map((fileName, index) => (<li key={index} className="list-group-item">{fileName}</li>))}
                              </ul>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <label className="form-container">No
                    <span>Please send me a price for designing my postcard.</span>
                    <input type="radio" name="graphicArtwork" value="No" onChange={() => handleChange("graphicArtwork", false)} />
                    <span className="checkmark"></span>
                  </label>
                  <label className="inputError">{err?.graphicArtwork && err.graphicArtwork}</label>
                </div>
                <div className="input-feild mb-30">
                  <label className="mb-30 font-20">Are you providing the Mailing List?</label>
                  <label className="form-container mb-30">Yes
                    <span>You may upload your list to us here.<span className='span'>Upload Your Files</span></span>
                    <input type="radio" name="mailingList" value="Yes" onChange={() => handleChange("mailingList", true)} />
                    <span className="checkmark"></span>
                  </label>
                  {isMailingListFile && (
                    <>
                      <label className="form-container mb-30">Upload CSV Files
                        <input type="checkbox" name="mailingCSV" value="Yes" onChange={(e) => handleChange("mailing csv", e.target.checked)} />
                        <span className="checkmark"></span>
                      </label>
                      {isMailingFile.csv && (
                        <>
                          <span className="file-span">Please upload CSV files</span>
                          <input type="file" name="mailingListFile" className="mb-30" onChange={(e) => handleFileChange(e, 'mailing csv')} accept=".csv" />
                          {mailingCSVImageFileList.length > 5 ? <label className="inputError">You can upload a maximum of 6 files.</label> : ""}
                          {mailingCSVImageFileList.length > 0 && (
                            <div className="container my-3 mt-3">
                              <h5>Uploaded CSV Files:</h5>
                              <ul className="list-group">
                                {mailingCSVImageFileList.map((fileName, index) => (<li key={index} className="list-group-item">{fileName}</li>))}
                              </ul>
                            </div>
                          )}
                        </>
                      )}
                      <label className="form-container mb-30">Upload TXT Files
                        <input type="checkbox" name="mailingTXT" value="Yes" onChange={(e) => handleChange("mailing txt", e.target.checked)} />
                        <span className="checkmark"></span>
                      </label>
                      {isMailingFile.txt && (
                        <>
                          <span className="file-span">Please upload TXT files</span>
                          <input type="file" name="mailingListFile" className="mb-30" onChange={(e) => handleFileChange(e, 'mailing txt')} accept=".txt" />
                          {mailingTXTFileList.length > 5 ? <label className="inputError">You can upload a maximum of 6 files.</label> : ""}
                          {mailingTXTFileList.length > 0 && (
                            <div className="container my-3 mt-3">
                              <h5>Uploaded TXT Files:</h5>
                              <ul className="list-group">
                                {mailingTXTFileList.map((fileName, index) => (<li key={index} className="list-group-item">{fileName}</li>))}
                              </ul>
                            </div>
                          )}
                        </>
                      )}
                      <label className="form-container mb-30">Upload ZIP Files
                        <input type="checkbox" name="mailingZIP" value="Yes" onChange={(e) => handleChange("mailing zip", e.target.checked)} />
                        <span className="checkmark"></span>
                      </label>
                      {isMailingFile.zip && (
                        <>
                          <span className="file-span">Please upload ZIP files</span>
                          <input type="file" name="mailingListFile" className="mb-30" onChange={(e) => handleFileChange(e, 'mailing zip')} accept=".zip" />
                          {mailingZIPFileList.length > 5 ? <label className="inputError">You can upload a maximum of 6 files.</label> : ""}
                          {mailingZIPFileList.length > 0 && (
                            <div className="container my-3 mt-3">
                              <h5>Uploaded ZIP Files:</h5>
                              <ul className="list-group">
                                {mailingZIPFileList.map((fileName, index) => (<li key={index} className="list-group-item">{fileName}</li>))}
                              </ul>
                            </div>
                          )}
                        </>
                      )}
                      <label className="form-container mb-30">XL Files
                        <input type="checkbox" name="mailingXL" value="Yes" onChange={handleXLFileChange} />
                        <span className="checkmark"></span>
                      </label>
                    </>
                  )}
                  <label className="form-container">No
                    <span>We will help you create your list.</span>
                    <input type="radio" name="mailingList" value="No" onChange={() => handleChange("mailingList", false)} />
                    <span className="checkmark"></span>
                  </label>
                  <label className="inputError">{err?.mailingList && err.mailingList}</label>
                </div>
              </div>

              <div className="input-feild mb-30 b-bottom">
                <label className="mb-30 font-20">What class of mail are you sending at? <span className="gray-clr font-18">(select one)</span></label>
                <div className="pia-className">
                  <label className="form-container mb-30">Marketing Mail (Standard)
                    <input type="radio" name='mailClass' value='Marketing Mail' />
                    <span className="checkmark"></span>
                  </label>
                  <label className="form-container mb-30">First Class Mail
                    <input type="radio" name='mailClass' value='First Class Mail' />
                    <span className="checkmark"></span>
                  </label>
                  <label className="form-container">Non-profit Mail
                    <input type="radio" name='mailClass' value='Non-profit Mail' />
                    <span className="checkmark"></span>
                  </label>
                  <label className='inputError'>{err?.mailClass && err.mailClass}</label>
                </div>
              </div>
              <div className="input-feild mb-30">
                <label className="mb-30 font-20">Describe the Carrier envelope (select one): </label>
                <div className="pia-className">
                  <label className="form-container mb-30">#10 regular
                    <input type="radio" name='carrierEnvelope' value='#10 regular' />
                    <span className="checkmark"></span>
                  </label>
                  <label className="form-container mb-30">#10 window
                    <input type="radio" name='carrierEnvelope' value='#10 window' />
                    <span className="checkmark"></span>
                  </label>
                  <label className="form-container mb-30">6x9
                    <input type="radio" name='carrierEnvelope' value='6x9' />
                    <span className="checkmark"></span>
                  </label>
                  <label className="form-container mb-30">9x12
                    <input type="radio" name='carrierEnvelope' value='9x12' />
                    <span className="checkmark"></span>
                  </label>
                  <div className="other-des d-flex align-items-center">
                    <p className="gray-clr me-4">other</p>
                    <div className="input-feild">
                      <label>Describe</label>
                      <input type="text" id="carrierEnvelopeOther" name="carrierEnvelopeOther" />
                    </div>
                  </div>
                  <label className='inputError'>{err?.carrierEnvelope && err.carrierEnvelope}</label>
                </div>
                <div className="input-feild mb-30">
                  <label className="mb-30 font-20">Artwork for envelope: (select) </label>
                  <div className="pia-className">
                    <label className="form-container mb-30">Single Color (black)
                      <input type="radio" name='envelopeArtwork' value='Single Color (black)' />
                      <span className="checkmark"></span>
                    </label>
                    <label className="form-container mb-30">2 Color
                      <input type="radio" name='envelopeArtwork' value='2 Color' />
                      <span className="checkmark"></span>
                    </label>
                    <label className="form-container mb-30">Full-Color
                      <input type="radio" name='envelopeArtwork' value='Full-Color' />
                      <span className="checkmark"></span>
                    </label>
                    <label className='inputError'>{err?.envelopeArtwork && err.envelopeArtwork}</label>
                  </div>
                </div>
                <div className='two-feild'>
                  <div className="input-feild mb-30">
                    <label className="mb-30 font-20">Is there a cover letter?</label>
                    <div className="pia-className">
                      <label className="form-container mb-30">Yes
                        <input type="radio" name='isCoverLetter' value='Yes' />
                        <span className="checkmark"></span>
                      </label>
                      <label className="form-container mb-30">No
                        <input type="radio" name='isCoverLetter' value='No' />
                        <span className="checkmark"></span>
                      </label>
                      <label className='inputError'>{err?.isCoverLetter && err.isCoverLetter}</label>
                    </div>
                  </div>
                  <div className="input-feild mb-30">
                    <label className="mb-30 font-20">Is it generic in salutation or personalized for each receiver?</label>
                    <div className="pia-className">
                      <label className="form-container mb-30">Generic
                        <input type="radio" name='coverLetterType' value='Generic' />
                        <span className="checkmark"></span>
                      </label>
                      <label className="form-container mb-30">Personalized
                        <input type="radio" name='coverLetterType' value='Personalized' />
                        <span className="checkmark"></span>
                      </label>
                      <label className='inputError'>{err?.coverLetterType && err.coverLetterType}</label>
                    </div>
                  </div>
                </div>

                <div className="input-feild mb-30">
                  <label className="mb-30 font-20">Artwork for cover letter: (select)</label>
                  <div className="pia-className">
                    <label className="form-container mb-30">Single Color (black)
                      <input type="radio" name='coverLetterArtwork' value='Single Color (black)' />
                      <span className="checkmark"></span>
                    </label>
                    <label className="form-container mb-30">Full-Color
                      <input type="radio" name='coverLetterArtwork' value='Full-Color' />
                      <span className="checkmark"></span>
                    </label>
                    <label className='inputError'>{err?.coverLetterArtwork && err.coverLetterArtwork}</label>
                  </div>
                </div>
                <div className="input-feild mb-30">
                  <label className="mb-30 font-20">Are there any additional inserts that have personalized variable text?</label>
                  <div className="pia-className">
                    <label className="form-container mb-30">Yes
                      <input type="radio" name='additionalInserts' value='Yes' />
                      <span className="checkmark"></span>
                    </label>
                    <label className="form-container mb-30">No
                      <input type="radio" name='additionalInserts' value='No' />
                      <span className="checkmark"></span>
                    </label>
                    <label className='inputError'>{err?.additionalInserts && err.additionalInserts}</label>
                  </div>
                </div>
                <div className="input-feild mb-30">
                  <label>How many total inserts go into envelope:<abbr>*</abbr></label>
                  <input type="number" id="totalInserts" name="totalInserts" />
                  <label className='inputError'>{err?.totalInserts && err.totalInserts}</label>
                </div>
                <div className="input-feild mb-30">
                  <label>Describe Inserts</label>
                  <input type="text" id="describeInserts" name="describeInserts" />
                  <label className='inputError'>{err?.describeInserts && err.describeInserts}</label>
                </div>
              </div>
              <div className="submit-btn">
                <button type='submit' className="btn r-btn hover-btn wave-btn">Submit</button>
              </div>
            </form>
          </>}
        </div>
      </div>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>XL Files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please send XL files as an email attachment directly to <a href="mailto:info@usamail.direct">info@usamail.direct</a>.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  )
}

export default LettersInEnvelopeForm;
