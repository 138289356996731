import React, { useEffect } from "react";
import TitleBannerCard from "../../commonCards/TitleBannerCard";
import { missionData } from "../../../constants/constants";
import { Helmet } from "react-helmet";
const MissionDetails = ({ title }) => {
  const missionDetail = missionData.filter(mission => mission.title === title)
  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "USA Mail Direct",
        "alternateName": "USA Mail Direct",
        "url": "https://usamail.direct/",
        "logo": ""
      }
    `}
        </script>
        <title>{missionDetail[0].seoTitle}</title>
        <meta name="description" content={missionDetail[0].seoDescription} />
      </Helmet>
      <TitleBannerCard title={title} className="article-bg" />
      <section className="achievements blog-sec sec_pd">
        <div className="container">
          <div className="article-details">
            <div className="article-details">
              <h1 className="sec_title">{missionDetail[0].heading}</h1>
              {missionDetail[0].details.map((content) => (
                content.startsWith("/heading") ? (
                  <h3 className="py-4">{content.replace("/heading", "")}</h3>
                ) : (
                  <p className="py-2" dangerouslySetInnerHTML={{ __html: content.replace(/<b>/g, "<b>").replace(/<\/b>/g, "</b>") }}

                  />
                )

              ))}

            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default MissionDetails;