import React, { useEffect } from 'react';
import TitleBannerCard from '../commonCards/TitleBannerCard';
import { faqQuestions } from '../../constants/constants';
import FaqCard from '../commonCards/FaqCard';
import { Helmet } from 'react-helmet';

export const FAQ = () => {
  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <Helmet>
        {/* Include the FAQPage JSON-LD script */}
        <script type="application/ld+json">
          {`
            {
              "@type": "Question",
              "name": "What is my first step in initiating a direct mail campaign?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The first thing you want to do is target your audience, i.e. whom do you want to mail to? What does your ideal customer look like? What are the demographics that define your ideal customer base? Deciding whom you want to mail to usually determines how many pieces of direct mail you will send out. It also determines what kind of mail piece you will create, i.e. postcard, brochure, enveloped letter, etc."
              }
            },{
              "@type": "Question",
              "name": "Do I need to go through the expense of purchasing my own bulk mailing permits from the post office?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "No. As long as you are sending out mail at the Presorted Standard or Presorted First Class postage rates, you can just use our mailing permits. The only condition to using Usa Mails mailing permit, is that Usa Mail has to be the organization that prepares and processes your mail. Also, only an agent of Usa Mail can present bulk-mail at the post office that has a Usa Mail mailing permit indicia printed on it."
              }
            },{
              "@type": "Question",
              "name": "How do I take advantage of maximum postage discounts?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Many people have the misconception that postage discounts are based on the volume of mail sent out at one time. This is inaccurate. Postage discounts are not volume based, but are based the how well the mail is pre-sorted and pre-barcoded. Every bulk mailing is inspected and verified by the post office upon entry into the mail stream. That is when the Post Office determines if you qualify for the discounted postage rates or not. In other words, postage discounts are determined by how your mailing list sorts out, based on postal regulations. Once we have your mailing list, the first thing we do is take out all of the duplicated addresses (there is no need to send duplicate mailings to the same address). Then we Move Update your mailing list to make sure that the person you are targeting is still at that address. Finally, we Zip+4/CASS certify your mailing list, which verifies the delivery address and assigns the nine-digit zipcode. Once we have all of your mailing list information updated, then we pre-sort and pre-barcode your mailing according to USPS regulations. That way your direct mail piece is ready to travel straight to the USPS barcode sorting machines. Presenting mail that is already pre-sorted and pre-barcoded means there is less work for the Post Office to do to process your mailing. In return, they give you reduced postage rates when we present your mail for processing."
              }
            },{
              "@type": "Question",
              "name": "Are there any design guidelines to consider when preparing a mass mailing?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes!! The United States Post Office has very specific rules and regulationsthat govern the processing of direct mail. It is critical that you follow all of the USPS guidelines as you are designing your mail piece. We also highly recommend that you let our Mail Piece Design Analyst inspect all of your artwork before you send anything off to be printed. Mail pieces that are not designed and printed properly usually end up be classified by the post office as non-automation compatible or  non-machineable, and therefore end up having to go at a higher postage rate. There have even been instances where mail pieces have been determined to be non-mailable, and could not be entered into the post office as bulk mail. As a rule, its always better to be safe than sorry. Dont print anything until we have a chance to look at it. Its as simple as emailing over a PDF proof for us to look at. Also, we encourage you to check out the Links section of our about mail piece design."
              }
            },{
              "@type": "Question",
              "name": "If we are a non-profit organization, does that automatically mean we qualify to mail at the Non-profit postage rates?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "No. In order to mail at the Non-profit postage rates you need to take the proper documentation to your post office and apply for Non-Profit Mailing Status. Once you are approved the post office will be give you a Non-Profit Authorization Number (NPA#). Once you get your NPA#, you can share that with us. By sharing your NPA# with us, we can prepare and send your mail out at the special Non-Profit postage rates. Please note: If you are going to have us prepare your Non-Profit mailings, you do not need to also purchase a Non-Profit mailing permit. You can mail using our permit as long as you have your own NPA#."
              }
            },{
              "@type": "Question",
              "name": "What is NCOA Move Update, and why is it important?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Move Update is the term used by the United State Postal Service (USPS) in regard to updating all mail delivery addresses with National Change of Address (NCOA) information. The post office now requires that some form of Move Update process be performed on all mailing lists being used in any kind of presorted or discounted postage mailings, i.e. bulk mail. In a nutshell, if you want to do any kind of bulk mailing, you have to make sure that the people on your mailing list that you are mailing to, still reside at the delivery addresses you are mailing to. The reason the Post Office has made this a requirement is because they are trying to reduce the amount of Undeliverable As Addressed (UAA) mail. In 2006 alone, over 9.7 billion pieces of mail were sent out with addresses that could not be delivered. This cost the USPS almost $1.9 billion to process, forward, return, or destroy.
          
          There are several factors that lead to a mail piece being undeliverable. One of the most common reasons is that the mail piece recipient has moved. Whenever someone fills out a change of address card, or goes to the USPS Change Of Address web page, a record is generated in the post offices NCOA master database. By comparing the addresses in your mailing list against this NCOA master database, we can find the new addresses of those who have moved, and thereby update your mailing list with the new information.Electronically updating your mailing list before you mail is the easiest way to conform to this mandatory USPS requirement. Mailers failing to comply with the Move Update rules stand to lose their automation discounts. This would apply to all the pieces in a given mailing, not just those pieces that couldnt be delivered. Obviously, any violation would also invite increased scrutiny of future mailings by the U.S. Postal Service. Here at Usa Mail Mailing Services we have the means to electronically Move Update your mailing lists prior to each mailing in order to make sure it complies with the USPS Move Update regulations.
          
          Move Updating your mailing list prior to each mailing is also an excellent cost saving measure. By cleaning up your mailing list before each mailing, removing undeliverable addresses, removing duplicates, and removing bad addresses, you will ultimately have to send out fewer pieces of mail. Why keep paying over and over again to send out mail that is undeliverable? Not only will Move Updating your database save you money on the overall cost of each mailing, but it will also allow your mail to be delivered directly to your target audience more quickly and accurately. This can dramatically improve your response rates."
              }
            },{
              "@type": "Question",
              "name": "What is the difference between Presorted Standard mail and Presorted First Class mail?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The postage due for Presorted Standard mail is less than the postage due for Presorted First Class mail. This is because the level of service provided by the post office is different between the two classes. For instance, Presorted Standard mail does not get forwarding or return service. If the mail piece is Undeliverable as Addressed (UAA), it is disposed of by the post office. Also, Standard mail primarily travels by ground on trucks, which can slow down delivery time, whereas Presorted First Class mail gets quicker delivery service, and even travels via airplane when required. Presorted First Class mail also gets forwarding and return service."
              }
            },{
              "@type": "Question",
              "name": "Can I send correspondence mail at the Standard mail or Non-profit mail postage rates?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "No. Any mail that has personal information in it particular to the person you are mailing it to, i.e. social security numbers, billing amounts, etc cannot travel at the Standard mail or Non-Profit mail postage rates. For instance, a church cannot send out their end-of-year statements-of-giving at the Non-profit postage rates. This is because this mail bears personal information, and therefore must be sent via First Class mail."
              }
            }
          `}
        </script>
      </Helmet>

      <TitleBannerCard title="FAQ" className="faq-title" />

      <section className="faq inner-padding">
        <div className="container">
          <h2 className="sec_title text-center black" data-aos="fade-left">
            Frequently Asked Questions
          </h2>
          <div
            className="accordion faq-accordion"
            id="accordionExample"
            data-aos="fade-right"
          >
            {faqQuestions.map((faq) => (
              <FaqCard key={faq.id} {...faq} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
