import React, { useRef, useState } from 'react'
import emailjs from 'emailjs-com';
const ContactForm = () => {
    const [err, setError] = useState(null)
    const formRef = useRef()
    const [response, setResponse] = useState(null)
    const resetForm = () => {
        formRef.current.reset();
        setTimeout(() => {
            setResponse(null)
        }, 1500)
    }
    const sendEmail = (e) => {
        e.preventDefault();
        let errors = {};

        const fieldNames = {
            name: 'Full Name',
            businessName: 'Business Name',
            emailAddress: 'Email Address',
            contact: 'Contact',
            message: 'Message'
        };
        const requiredFields = ['name', 'businessName', 'emailAddress', 'contact', 'message'];

        for (const field of requiredFields) {
            const value = e.target[field].value.trim();
            if (!value) {
                // Use the field as a key in the errors object
                errors[field] = `Please fill in the ${fieldNames[field]}  field.`;
            }
        }
        setError(errors);
        if (Object.keys(errors).length === 0) {
            const serviceID = 'service_nu2tww4';
            const templateID = 'template_tcd37u8';
            const publicKey = 'IqMFFU3Z2aSszwIQ-'

            const formData = {
                name: e.target['name'].value,
                businessName: e.target['businessName'].value,
                emailAddress: e.target['emailAddress'].value,
                contact: e.target['contact'].value,
                message: e.target['message'].value

            };

            // Send email using EmailJS
            emailjs.send(serviceID, templateID, formData, publicKey,)
                .then((response) => {
                    setResponse('Email sent successfully')
                    resetForm()
                })
                .catch((error) => {
                    setResponse('Failed to send email')
                    resetForm()
                });
        }
    };
    return (
        <section className="contact-form inner-padding">
            <div className="contact-page-inner">
                <div className="container">
                    <div className="form-inner" data-aos="zoom-in-up">
                        {response ? <div className='text-center h3'>{response}</div> : <>
                            <form className='usa-mail-contact-us-form' id='usa-mail-contact-us-form' ref={formRef} onSubmit={sendEmail}>
                                <h2 style={{ color: "black" }} className="sec_title text-center">Get in Touch
                                </h2>
                                <div className="two-list">
                                    <div className='col-lg-12 d-lg-flex col-12 gap-2'>

                                        <div className="col-lg-6 col-12 mt-2">
                                            <input type="text" placeholder="Full Name" name='name' />
                                            <label className='text-danger py-1'>{err?.name && err.name}</label>
                                        </div>
                                        <div className="col-lg-6 col-12 mt-2">
                                            <input type="text" placeholder="Business Name" name='businessName' />
                                            <label className='text-danger py-1'>{err?.businessName && err.businessName}</label>
                                        </div>
                                    </div>

                                    <div className='col-lg-12 d-lg-flex col-12 gap-2'>
                                        <div className="col-lg-6 col-12 mt-2">
                                            <input type="tel" placeholder="Phone Number" name='contact' />
                                            <label className='text-danger py-1'>{err?.contact && err.contact}</label>
                                        </div>
                                        <div className="col-lg-6 col-12 mt-2">
                                            <input type="mail" placeholder="Email" name='emailAddress' />
                                            <label className='text-danger py-1'>{err?.emailAddress && err.emailAddress}</label>
                                        </div>
                                    </div>

                                </div>
                                <div className="form-msg mb-30 mt-2">
                                    <textarea placeholder="Write your message here" name='message'></textarea>
                                    <label className='text-danger py-1'>{err?.message && err.message}</label>
                                </div>
                                <div className="submit-btn mt-8">
                                    <button type='submit' className="btn r-btn hover-btn wave-btn">Submit</button>
                                </div>

                            </form>
                        </>}

                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm