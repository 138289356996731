import React, { useEffect, useState } from 'react'
import { articles, categories, popularArticles } from '../../../constants/constants'
import ArticleCard from '../../commonCards/ArticleCard'
import RightArticleCard from '../../commonCards/RightArticleCard'
const Articles = () => {
  const filterArticlesByDate = (articles) => {
    const currentDate = new Date();

    return articles.filter((article) => {
      const articleDate = new Date(article.date);
      return articleDate <= currentDate;
    });
  };

  // Use the function to get filtered articles
  const filteredArticles = filterArticlesByDate(articles);

  // Now `filteredArticles` contains only the articles with dates equal to or after the current date


  return (
    <section className="achievements blog-sec sec_pd">
      <div className="container">
        <div className="d-flex flex-wrap article-box">
          <div className="article-left-box" data-aos="fade-right">
            {filteredArticles.map(article => <ArticleCard {...article} />)}
          </div>

        </div>
      </div>
    </section>
  )
}

export default Articles