import React from "react";
import { articles } from "../../constants/constants";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const ArticleDetailCard = () => {
  let { id } = useParams();
  const articleDetails = articles.filter(i => i.id === id)


  return (
    <>
      <Helmet>
        <title>{articleDetails[0].seoTitle}</title>
        <meta name="description" content={articleDetails[0].seoDescription} />
      </Helmet>
      <div class="article-details articles-detail-main">
        <h1 class="sec_title">{articleDetails[0].heading}</h1>
        <h5 className="text-primary py-3">{articleDetails[0].date}</h5>
        {articleDetails[0].id === "making-direct-mail-work-for-you" || articleDetails[0].id === "direct-mail-top-10-rules-for-success" ?
          < div className="row ">
            <div className="col-lg-12">
              <img src={articleDetails[0].image} alt="Image 1" className="img-fluid custom-image articles-details-images" />
            </div>

          </div> : ""
        }
        {articleDetails[0].details.map((content, index) => {
          if (content.startsWith("/heading")) {
            return <h3 className="py-2" key={index}>{content.replace("/heading", "")}</h3>;
          } else {
            // Check if the current paragraph is the one after which you want to insert the image
            const isImageParagraph = index === 1;

            return (
              <div className="py-2" key={index}>
                <p dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, "<br />") }} />
                {articleDetails[0].id === "making-direct-mail-work-for-you" || articleDetails[0].id === "direct-mail-top-10-rules-for-success" ? isImageParagraph && (
                  <div className="col-lg-12 mt-3">
                    <img src={articleDetails[0].detailsImage} alt="Image" className="img-fluid custom-image articles-details-images" />
                  </div>
                ) : ""}
              </div>
            );
          }
        })}
      </div>
    </>
  );
};

export default ArticleDetailCard;