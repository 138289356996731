import React, { useEffect } from 'react'
import TitleBannerCard from '../commonCards/TitleBannerCard'
import ContactCard from '../commonCards/ContactCard'
import { contactData } from '../../constants/constants'
import ContactForm from './ContactUsComponents/ContactForm'
import { Helmet } from 'react-helmet'
export const ContactUs = () => {
  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    })
  }
  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "USA Mail Direct",
        "alternateName": "USA Mail Direct",
        "url": "https://usamail.direct/",
        "logo": ""
      }
    `}
        </script>
        <title>Contact USA Mail Direct - Direct Mail Service Near Me</title>
        <meta name="description" content="Need a reliable direct mail service? Look no further. Contact USA Mail Direct for fast, efficient, and cost-effective direct mail solutions. Boost your marketing efforts today." />
      </Helmet>
      <main>
        <TitleBannerCard title='CONTACT US' className='contact-bg' />
        <section className="contact-page inner-padding">
          <div className="contact-page-inner">
            <div className="container">
              <div className="row">
                {contactData.map(contact => <ContactCard {...contact} />)}
              </div>
            </div>
          </div>
        </section>
        <ContactForm />
      </main>
    </>
  )
}
